var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn f-centered pointer",class:{ 
    disabled: _vm.disabled || _vm.loading,
    primary: _vm.primary, secondary: _vm.secondary, tertiary: _vm.tertiary, danger: _vm.danger, link: _vm.link, compact: _vm.compact, full: _vm.full, round: _vm.round, rounded: _vm.rounded,
    icon: !_vm.hasContent,
    outline: _vm.outline,
    'text-center': !_vm.textLeft,
    [_vm.size]: _vm.size != 'normal',
  },on:{"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.icon && !_vm.loading)?_c('SvgIcon',{class:{
      primary: _vm.iconColor == '' && (_vm.link || _vm.secondary),
      white: _vm.primary || (_vm.danger && !_vm.outline),
      mr1: _vm.hasContent && !_vm.fullIcon,
      red: _vm.danger && _vm.outline,
      [_vm.iconColor]: _vm.iconColor != '',
    },attrs:{"icon-name":_vm.icon,"not-filled":_vm.iconNotFilled,"only-stroke":_vm.onlyStroke,"viewBox":_vm.size != 'small' && !_vm.icon ? '0 0 16 16' : undefined,"fill":_vm.primary ? 'white' : undefined,"width":!_vm.smallIcon ? 24 : 16,"height":!_vm.smallIcon ? 24 : 16}}):_vm._e(),_vm._v(" "),(_vm.loading)?_c('SvgIcon',{staticClass:"mr2",attrs:{"width":"24","icon-name":"loading-90"}}):_vm._e(),_vm._v(" "),(typeof _vm.text === 'function')?_c('div',{staticClass:"w-100"},[_vm._v(_vm._s(_vm.text()))]):(_vm.text != undefined && _vm.text != '')?_c('div',{directives:[{name:"t",rawName:"v-t",value:({path: _vm.text, args: _vm.textParams}),expression:"{path: text, args: textParams}"}],staticClass:"w-100"}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.rightIcon && !_vm.loading)?_c('SvgIcon',{class:{
      primary: _vm.iconColor == '' && (_vm.link || _vm.secondary),
      white: _vm.primary,
      red: _vm.danger,
      [_vm.iconColor]: _vm.iconColor != '',
    },attrs:{"icon-name":_vm.rightIcon,"not-filled":_vm.iconNotFilled,"fill":_vm.primary ? 'white' : undefined,"width":24,"height":24}}):_vm._e(),_vm._v(" "),(_vm.dropdown)?_c('SvgIcon',{class:_vm.primary ? 'white' : 'primary',attrs:{"icon-name":"chevron_down","width":16,"height":16}}):_vm._e(),_vm._v(" "),(_vm.badge)?_c('Badge',{attrs:{"value":_vm.badge,"bg":_vm.badgeColor,"color":"white","size":"8","absolute":""}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }