<template>
  <div 
    class="btn f-centered pointer"
    :class="{ 
      disabled: disabled || loading,
      primary, secondary, tertiary, danger, link, compact, full, round, rounded,
      icon: !hasContent,
      outline,
      'text-center': !textLeft,
      [size]: size != 'normal',
    }"
    @click="$emit('click', $event)"
  >
    <SvgIcon
      v-if="icon && !loading"
      :icon-name="icon"
      :class="{
        primary: iconColor == '' && (link || secondary),
        white: primary || (danger && !outline),
        mr1: hasContent && !fullIcon,
        red: danger && outline,
        [iconColor]: iconColor != '',
      }"
      :not-filled="iconNotFilled"
      :only-stroke="onlyStroke"
      :viewBox="size != 'small' && !icon ? '0 0 16 16' : undefined"
      :fill="primary ? 'white' : undefined"
      :width="!smallIcon ? 24 : 16" 
      :height="!smallIcon ? 24 : 16" 
    />
    <SvgIcon
      v-if="loading"
      width="24"
      icon-name="loading-90"
      class="mr2"
    />
    <!-- <i v-if="icon && ($slots.default || text)" :class="['icon', icon]"/>
    <i v-else-if="icon && !$slots.default && !text" class="icon mr0 big" :class="icon"/> -->
    <div v-if="typeof text === 'function'" class="w-100">{{ text() }}</div>
    <div v-else-if="text != undefined && text != ''" class="w-100" v-t="{path: text, args: textParams}" />
    <slot />
    <SvgIcon
      v-if="rightIcon && !loading"
      :icon-name="rightIcon"
      :class="{
        primary: iconColor == '' && (link || secondary),
        white: primary,
        red: danger,
        [iconColor]: iconColor != '',
      }"
      :not-filled="iconNotFilled"
      :fill="primary ? 'white' : undefined"
      :width="24" 
      :height="24" 
    />
    <SvgIcon v-if="dropdown" icon-name="chevron_down"
      :width="16"
      :height="16"
      :class="primary ? 'white' : 'primary'"
    />
    <Badge v-if="badge" :value="badge" :bg="badgeColor" color="white" size="8" absolute />
  </div>
</template>

<script>
export default {
  components: {
    SvgIcon: () => import('./svg-icon'),
    Badge: () => import('./Badge'),
  },
  props: {
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    tertiary: {
      type: Boolean,
      default: false,
    },
    text: {
      type: [String, Number, Function]
    },
    textParams: {
      type: Object,
      default: () => null
    },
    textLeft: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String
    },
    rightIcon: {
      type: String
    },
    smallIcon: {
      type: Boolean,
      default: false,
    },
    // заставляет иконку быть размером 24*24
    fullIcon: {
      type: Boolean,
      default: false,
    },
    // запрещает применять CSS "fill" для SVG
    iconNotFilled: {
      type: Boolean,
      default: false,
    },
    // убирает заливку но добавляет обводку
    onlyStroke: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'normal',
      validate(value) {
        return ['tiny','small', 'normal', 'big', 'large'].includes(value)
      },
    },
    link: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: [String, Number],
      default: '',
    },
    badgeColor: {
      type: String,
      default: 'white',
    }
  },
  computed: {
    hasContent() {
      return this.$slots.default || (this.text != undefined && this.text != '')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/vars'
.btn 
  padding 6px 12px
  width max-content
  height fit-content
  line-height 23px
  position relative
  &.abs 
    position absolute
  // border: 1px solid #E6F0FF;
  border-radius: 6px;
  &.full
    width 100%
  // &:not(.icon)
    // height 32px
  &.tiny
    height 16px
    width 16px
  &.small
    height 24px
  &.big
    height 40px
    // width 40px
  &.large
    height 45px
    width 140px
  &:not(.icon):hover
    color $button_hover
    // border-color: #0255CA;
  &.primary
    color white
    background $button_color
    box-shadow 0px 2px 5px rgba(87, 86, 224, 0.26)
    border-radius 5px
    &:hover
      background-color #3588FD
      color #E6F0FF
    &:focus
      border 2px solid #9AC4FE
  &.secondary
    background-color $button_secondary
    color $button_color
    border-radius 5px
  &.tertiary
    border 1px solid #E2E5E9
    box-shadow: 0px 1px 4px 0px #00000014;
  &.icon
    padding 0 4px
    height 32px
    width 32px
    &.small
      height 28px
      width 28px
    &.tiny
      padding 0 2px
      height 16px
      width 16px
  &.link
    min-width auto
    border none
    background-color transparent
    color $button_color
  &.danger
    color $white
    background-color $red
    &.link
      color $red
      background-color transparent
    &.outline
      color $red
      background-color $white
      border 1px solid $red
  &.light
    color $button_color
    opacity 0.5
  &.compact
    padding-top 0
    padding-bottom 0
    height 22px
  &.disabled
    pointer-events none
    filter opacity(0.5)
  &.round
    border-radius 50%
  &.rounded
    border-radius: 16px;
    &.small
      padding: 4px 4px;
</style>