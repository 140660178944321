// import actions
import * as Actions from './actions.type'

// import mutations
import * as Mutations from './mutations.type'

// import service functions
import * as Service from './article.service'

type ArticleFile = {
  _id: string,
  type: string,
  filename: string,
  path: string,
}
type ArticleRights = {
  [K in 'all' | 'all_heads' | 'email' | 'dep']?: string | boolean
}[]

type View = {
  user: string,
  time: Date,
}

type Article = {
  _id: string,
  workspace: string,
  header: string,
  body: string,
  author: string,
  parent?: string,
  shortLink: string,
  order: number,
  created: Date,
  breadcrumbs: object[],
  activities: string[],
  files: ArticleFile[],
  views: View[],
  grants: ArticleRights,
}

interface State {
  articles: Article[],
  vacancies: Article[],
  folder: Article | null,
  article: Article | null,
  service_page: Article | null,
  vacancy: Article | null,
  children: Article[],
  breadcrumbs: object[] | null,
  tree: object[],
  grants: ArticleRights,
  //<fields of model>
  //field: any | null
}

const store: State = {
  articles: [],
  vacancies: [],
  folder: null,
  article: null,
  service_page: null,
  vacancy: null,
  children: [],
  breadcrumbs: [],
  tree: [],
  grants: [],
}

const getters = {
  articles(state: State) {
    return state.articles
  },
  vacancies(state: State) {
    return state.vacancies
  },
  article(state: State) {
    return state.article
  },
  service_page(state: State) {
    return state.service_page
  },
  vacancy(state: State) {
    return state.vacancy
  },
  children(state: State) {
    return state.children
  },
  breadcrumbs(state: State) {
    return state.breadcrumbs
  },
  tree(state: State) {
    return state.tree
  },
}
type FileInfo = { _id: string, type: string, path: string, filename: string }
type ArticleRightsData = {
  id: string,
  grants: ArticleRights
}
const mutations = {
  [Mutations.SET_ARTICLES](state: State, articles: Article[]) {
    state.articles = articles;
  },
  [Mutations.SET_BREADCRUMBS](state: State, breadcrumbs: object[]) {
    state.breadcrumbs = breadcrumbs;
  },
  [Mutations.SET_TREE](state: State, tree: object[]) {
    state.tree = tree;
  },
  [Mutations.SET_VACANCIES](state: State, vacancies: Article[]) {
    state.vacancies = vacancies;
  },
  [Mutations.SET_FOLDER](state: State, folder: Article) {
    state.folder = folder;
  },
  [Mutations.SET_ARTICLE](state: State, art: Article) {
    state.article = art;
    state.articles = state.articles.map(a => a._id == art._id ? art : a) 
  },
  // в ids массив удаленных объектов, даже если это одна статья
  [Mutations.REMOVE_ARTICLE](state: State, ids: any[]) {
    const _ids = ids.map(o => o.id)
    state.articles = state.articles.filter(a => !_ids.includes(a._id));
  },
  [Mutations.REMOVE_VACANCY](state: State, ids: any[]) {
    const _ids = ids.map(o => o.id)
    state.vacancies = state.vacancies.filter(a => !_ids.includes(a._id));
  },
  [Mutations.CHANGE_ARTICLES_ORDER](state: State, data: { from: number, to: number}) {
    const from_i = state.articles.findIndex(a => a.order == data.from)
    const to_i = state.articles.findIndex(a => a.order == data.to)
    
    state.articles = state.articles.map((art, i) => {
      if (i == from_i) art.order = +data.to
      if (i == to_i) art.order = +data.from
      return art
    })
  },
  [Mutations.SET_CHILDREN](state: State, children: Article[]) {
    state.children = children
  },
  [Mutations.ARTICLE_ADD_FILES](state: State, files: FileInfo[] ) {
    if (state.article) {
      state.article = {
        ...state.article,
        files: [...state.article.files, ...files]
      }
    }
  },
  [Mutations.ARTICLE_REMOVE_FILES](state: State, _ids: string[]) {
    state.article = {
      ...state.article,
      files: state.article?.files.filter((f: ArticleFile) => !_ids.includes(f._id)) as ArticleFile[]
    } as Article
  },
  [Mutations.SET_VIEWED](state: State, view: {user: string, time: string}) {
    if (state.article) state.article.views.push({user: view.user, time: new Date(view.time)})
  },
  [Mutations.SET_RIGHTS](state: State, rights: ArticleRightsData) {
    const article = state.articles.find(a => a._id == rights.id)
    if (article) {
      article.grants = rights.grants
    }    
  }
}

const actions = {
  [Actions.GET_ARTICLES]: Service.getArticles,
  [Actions.GET_BREADCRUMBS]: Service.getBreadcrumbs,
  [Actions.GET_TREE]: Service.getTree,
  [Actions.GET_VACANCIES]: Service.getVacancies,
  [Actions.GET_FOLDER]: Service.getFolder,
  [Actions.GET_ARTICLE]: Service.getOneArticle,
  [Actions.GET_CHILDREN]: Service.getChildren,
  [Actions.CREATE_ARTICLE]: Service.addArticle,
  [Actions.DELETE_ARTICLE]: Service.delArticle,
  [Actions.DELETE_VACANCY]: Service.delVacancy,
  [Actions.CHANGE_ARTICLE]: Service.updArticle,
  [Actions.ARTICLE_DEL_FILE]: Service.delFile,
  [Actions.ARTICLE_DEL_ALL_FILES]: Service.delAllFiles,
  [Actions.ARTICLE_UPLOAD_FILE]: Service.uploadFile,
  [Actions.SET_ALL_ARTICLES_AS_ARTICLES]: Service.setSectionToAllArticles,
  [Actions.CHANGE_ORDER]: Service.changeOrder,
  [Actions.VAC_RESPOND]: Service.vacancyRespond,
  [Actions.VIEW_ARTICLE]: Service.setViewed,
  [Actions.SET_RIGHTS]: Service.setRights,
}

export default {
  state: store,
  getters,
  actions,
  mutations
};