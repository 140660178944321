// import {} from './actions.type'
// import {} from './mutations.type'

import * as Service from "./service"
import * as Actions from "./actions.type"
import * as Mutations from "./mutations.type"
import * as Methods from './mutations/methods'

import { Desk, Task } from './types'

export interface State {
  list: Desk[], // список досок для начальной страницы
  item: Desk | null,  // активная доска для ее страницы 
  activeTask: Task | null, // открытая в окне редактирования задача
}


// STORE 
const store: State = {
  list: [], // единый реквизит для списка объектов из общего модуля CRUD (common)
  item: null, // аналогично для активного объекта
  activeTask: null,
}

const actions = {
  [Actions.GET_TASK]: Service.getTaskOfDesk,
  [Actions.CREATE_TASK]: Service.saveTaskAlone,
  [Actions.NEW_TASK]: Service.addTaskToDesk,
  [Actions.EDIT_TASK]: Service.editTaskOnDesk,
  [Actions.DEL_TASK]: Service.delTask,
  [Actions.DEL_LANE]: Service.delLaneOfDesk,
  [Actions.MOVE_TASK]: Service.moveTaskToLane,
  [Actions.DESK_FINISH]: Service.finishDesk,
}

// const getLane = (state, id: string) => state.item?.lanes.find(l => l._id === id)

// изменение задач внутри активной доски
const mutations = {
  [Mutations.ADD_TASK]: Methods.addTask,
  [Mutations.CHANGE_TASK]: Methods.changeTask,
  [Mutations.REMOVE_TASK]: Methods.removeTask,
  [Mutations.CHANGE_FILES]: Methods.changeFiles,
  [Mutations.ADD_COMMENT_FOR_TASK]: Methods.addCommentToTask,
  [Mutations.CHANGE_COMMENT_FOR_TASK]: Methods.editCommentToTask,
  [Mutations.REMOVE_COMMENT]: Methods.deleteCommentInTask,
}

export default {
  namespaced: true,
  state: store,
  // getters,
  actions,
  mutations,
};