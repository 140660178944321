// import actions
import * as Actions from '@/store/service/actions.type'
import * as Mutations from '@/store/service/mutations.type'
import * as Services from './service.service'
import { TService, TServiceGroup, TBusinessProcess, BPStep } from './types'
import { Comment } from '../post/types'

interface State {
  // сервисы для текущей статьи
  services: TService[],
  service_groups: TServiceGroup[],
  bps: TBusinessProcess[],
  bp: TBusinessProcess | null,
  table: TBusinessProcess[],
  my_orders: TBusinessProcess[],
}

const store: State = {
  services: [], // список сервисов - по сути шаблоны бизнес процессов
  service_groups: [],  // список групп сервисов
  bps: [],
  bp: null,
  table: [],
  my_orders: [],
}

// const getters = {
//   services(state: State) {
//     return state.services
//   },
//   service_groups(state: State) {
//     return state.service_groups
//   },
// }

/**
 * Замена элемента в массиве
 * 
 * @param arr Массив для модификации
 * @param el Элемент для замены
 * @param fn Функция поиска позиции
 */
const changeElement = <T>(arr: any[T], el: T, fn: (s: T) => boolean): any[T] => {
  const i = arr.findIndex(fn)
  return [...arr.slice(0, i), el, ...arr.slice(i + 1)]
}

const mutations = {
  // старые сервисы для совместимости пока выводим
  [Mutations.SET_SERVICES](state: State, data: any) {
    state.services = data;
  },
  // новые бизнес-процессы (шаблоны)
  [Mutations.SET_BUSINESS_PROCESSES](state: State, items: TBusinessProcess[]) {
    state.bps = items;
  },
  // запущенные заявки по бизнес-процессам
  [Mutations.SET_ORDERS_TABLE](state: State, items: TBusinessProcess[]) {
    state.table = items;
  },
  [Mutations.SET_MY_PROCESSES](state: State, items: TBusinessProcess[]) {
    state.my_orders = items;
  },
  // редактируемый бизнес-процесс
  [Mutations.SET_BUSINESS_PROCESS](state: State, item: TBusinessProcess) {
    state.bp = item;
  },
  // изменить один процесс в списке (когда надо? видимо всегда вместе с SET_BUSINESS_PROCESS)
  [Mutations.SET_BP](state: State, data: TBusinessProcess) {
    //const i = state.services.findIndex(s => s._id == data._id)
    state.bps = changeElement<TBusinessProcess>(state.bps, data, (bp: TBusinessProcess) => bp._id == data._id)
  },
  [Mutations.ADD_BP](state: State, bp: TBusinessProcess) {
    state.bps = [...state.bps, bp]
  },
  [Mutations.REMOVE_BP](state: State, bp_id: string) {
    state.bps = state.bps.filter(s => s._id !== bp_id)
  },
  [Mutations.ADD_STEP](state: State, data: {bp: string, step: BPStep}) {
    state.bp?.steps.push(data.step)
  },
  [Mutations.CHANGE_STEP](state: State, data: {bp: string, step: BPStep}) {
    if (!state.bp) return
    state.bp.steps = changeElement<BPStep>(state.bp.steps, data.step, (stp: BPStep) => stp._id == data.step._id)
  },
  [Mutations.REMOVE_STEP](state: State, data: {bp: string, step_id: string}) {
    if (!state.bp) return
    state.bp.steps = state.bp.steps.filter(s => s._id !== data.step_id)
  },
  /// SERVICE GROUPS
  [Mutations.SET_SERVICE_GROUPS](state: State, data: any) {
    state.service_groups = data;
  },
  [Mutations.SET_SERVICE_GROUP](state: State, data: TServiceGroup) {
    //const i = state.services.findIndex(s => s._id == data._id)
    state.service_groups = changeElement<TServiceGroup>(state.service_groups, data, (srv: TServiceGroup) => srv._id == data._id)
  },
  [Mutations.ADD_SERVICE_GROUP](state: State, data: TServiceGroup) {
    state.service_groups = [...state.service_groups, data]
  },
  [Mutations.REMOVE_SERVICE_GROUP](state: State, srv_id: string) {
    state.service_groups = state.service_groups.filter(s => s._id !== srv_id)
  },
  [Mutations.ADD_COMMENT_FOR_SERVICE](state: State, comment: Comment) {
    if (state.bp == null) return
    state.bp.comments = [comment, ...state.bp.comments]
  }
}

const actions = {
  [Actions.GET_SERVICES]: Services.getServices,
  [Actions.GET_BUSINESS_PROCESSES]: Services.getBusinessProcesses,
  [Actions.GET_PROCESSES]: Services.getRunningProcesses,
  [Actions.GET_BP]: Services.getBP,  // создание нового бизнес процесса и запуск тоже
  [Actions.CREATE_BP]: Services.newBP,  // создание нового бизнес процесса и запуск тоже
  [Actions.CHANGE_BP]: Services.updateBP, // изменение бизнес процесса
  [Actions.DELETE_BP]: Services.delBP,
  [Actions.CONVERT_BP]: Services.convertProcesses, // конвертация старых сервисов в шаблоны процессов
  // Шаги, для удобства и уменьшения объема запросов
  [Actions.CHANGE_STEP]: Services.updateStep, // изменение шага
  [Actions.CREATE_STEP]: Services.newStep, // создание шага
  [Actions.DELETE_STEP]: Services.deleteStep, // удаление шага
  [Actions.STEP_PASS]: Services.passStep, // согласование или отклонение шага
  // SERVICE GROUPS
  [Actions.GET_SERVICE_GROUPS]: Services.getServiceGroups,
  [Actions.CREATE_SERVICE_GROUP]: Services.newServiceGroup,
  [Actions.CHANGE_SERVICE_GROUP]: Services.updateServiceGroup,
  [Actions.DELETE_SERVICE_GROUP]: Services.delServiceGroup
}

export default {
  namespaced: true,
  state: store,
  actions,
  mutations
};