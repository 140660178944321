import Home from '@/modules/SinglePages/Home.vue';

const view = (name: string) => {
  return () => import(`@/views/${name}.vue`);
};

const viewModule = (name: string, page = 'index') => {
  return () => import(`@/modules/${name}/${page}.vue`);
}

export default [
  /*{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/!* webpackChunkName: "about" *!/ './views/About.vue'),
  },*/
  {
    path: '/login/',
    name: 'login',
    component: viewModule('SinglePages','Login'),
  },
  {
    path: '/login_new/',
    name: 'login_new',
    component: viewModule('SinglePages','LoginNew'),
  },
  {
    name: 'authorize',
    path: '/authorize',
    component: view('Authorize'),
  },
  {
    path: '/register/',
    name: 'register',
    component: view('Register'),
  },
  {
    path: '/forgot-password/',
    name: 'ForgotPassword',
    component: viewModule('SinglePages','ForgotPassword'),
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: viewModule('SinglePages','ResetPassword'),
  },
  {
    path: '/',
    name: '',
    component: Home,
    children: [
      {
        path: '/company',
        name: 'company',
        component: viewModule('Company'),
        meta: {
          section: 'company'
        },
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: viewModule('Notifications'),
        meta: {
          section: 'notifications'
        },
      },
      {
        path: '/post/:_id',
        name: 'post',
        component: viewModule('Posts'),
        meta: {
          section: 'posts'
        },
      },
      {
        path: '/profile/:_id',
        name: 'profile',
        component: viewModule('UserProfile'),
        meta: {
          section: 'profile'
        },
      },
      {
        path: '/calendar/',
        name: 'calendar',
        component: viewModule('Calendar'),
        meta: {
          section: 'calendar'
        },
      },
      {
        path: '/addressBook/',
        name: 'addressBook',
        component: viewModule('AddressBook'),
        meta: {
          section: 'addressBook'
        },
      },
      {
        path: '/groups/',
        name: 'groups',
        component: viewModule('Groups'),
        meta: {
          section: 'groups'
        },
      },
      {
        path: '/groups/:_id',
        name: 'group',
        component: viewModule('Groups', 'Group'),
        meta: {
          section: 'groups'
        },
      },
      {
        path: '/group/:_id',
        name: 'group',
        component: viewModule('Groups', 'Group'),
        meta: {
          section: 'groups'
        },
      },
      {
        path: '/invite/:_id',
        name: 'invite',
        component: viewModule('SinglePages','Invite'),
        meta: {
          section: 'invite'
        },
      },
      {
        path: '/articles/',
        name: 'articles',
        component: viewModule('Articles'),
        meta: {
          section: 'articles'
        },
      },
      {
        path: '/articles/:parent',
        name: 'articlesByParent',
        component: viewModule('Articles'),
        meta: {
          section: 'articles'
        },
      },
      {
        path: '/article/:_id',
        name: 'article',
        component: viewModule('Articles', 'Article'),
        meta: {
          section: 'articles'
        },
      },
      {
        path: '/vacancies/',
        name: 'vacancies',
        component: viewModule('Articles','Vacancies'),
        meta: {
          section: 'vacancies'
        },
      },
      {
        path: '/vacancy/:_id',
        name: 'vacancy',
        component: viewModule('Articles','Article'),
        meta: {
          section: 'vacancies'
        },
      },
      {
        path: '/services/',
        name: 'services',
        component: viewModule('Services'),
        meta: {
          section: 'services'
        },
      },
      {
        path: '/service/:_id',
        name: 'service',
        component: viewModule('Services','Service'),
        meta: {
          section: 'services'
        },
      },
      {
        path: '/structure_new',
        name: 'structure_new',
        component: viewModule('Structures'),
        meta: {
          section: 'structure_new'
        },
      },
      {
        path: '/idea',
        name: 'idea',
        component: viewModule('Ideas'),
        meta: {
          section: 'idea'
        },
      },
      {
        path: '/report_violation',
        name: 'report_violation',
        component: viewModule('NoSilent'),
        meta: {
          section: 'report_violation'
        },
      },
      {
        path: '/employees/',
        name: 'employees',
        component: viewModule('CompanyManagement'),
        meta: {
          section: 'employees'
        },
      },
      {
        path: '/clients',
        name: 'clients',
        component: viewModule('Clients'),
        meta: {
          section: 'clients'
        },
      },
      {
        path: '/polls',
        name: 'polls',
        component: viewModule('Polls'),
        meta: {
          section: 'polls'
        },
      },
      {
        path: '/poll/new',
        name: 'new_poll',
        component: viewModule('Polls','Poll'),
        props: { isNew: true },
        meta: {
          section: 'polls'
        },
      },
      {
        path: '/poll/:_id',
        name: 'poll',
        component: viewModule('Polls','Poll'),
        props: true,
        meta: {
          section: 'polls'
        },
      },
      {
        path: '/chat',
        name: 'chats',
        component: viewModule('Chat'),
        meta: {
          section: 'chat'
        },
      },
      {
        path: '/chat/:_id',
        name: 'chat',
        component: viewModule('Chat'),
        meta: {
          section: 'chat'
        },
      },
      {
        path: '/poll/:_id/results',
        name: 'poll_results',
        component: viewModule('Polls','PollResults'),
        props: true,
        meta: {
          section: 'polls'
        },
      },
      {
        path: '/shop/',
        name: 'shop',
        component: viewModule('Shop'),
        meta: {
          section: 'shop'
        },
      },
      {
        path: '/project/:_id',
        name: 'project',
        component: view('Project'),
        meta: {
          section: 'projects'
        },
      },
      {
        path: '/admin/',
        name: 'admin',
        component: viewModule('AdminPanel'),
        meta: {
          section: 'admin'
        },
      },
      {
        path: '/about/',
        name: 'about',
        component: viewModule('SinglePages','About'),
        meta: {
          section: 'about'
        },
      },
      {
        path: '/birthdays/',
        name: 'birthdays',
        component: viewModule('SinglePages','Birthdays'),
        meta: {
          section: 'birthdays'
        },
      },
      {
        path: '/task-tracker/',
        name: 'task_tracker',
        component: viewModule('TaskTracker'),
        meta: {
          section: 'task_tracker'
        },
      },
      {
        path: '/desk/:_id',
        name: 'tasks_desk',
        component: viewModule('TaskTracker', 'TasksDesk'),
        meta: {
          section: 'tasks_desk'
        },
      },
      {
        path: '/adaptation',
        name: 'adaptation',
        component: viewModule('Adaptation'),
        meta: {
          section: 'adaptation'
        },
        children: [
          {
            // Страница пользовательской адаптации
            path: '/adaptation/user/:adapt_id',
            name: 'adaptation_user',
            component: viewModule('Adaptation', 'components/ProgressPage/TrackForUser'),
            meta: {
              section: 'adaptation'
            },
          }
        ]
      },
      {
        // Админская страница управления данными о треке
        path: '/adaptation/:_id',
        name: 'adaptation_track',
        component: viewModule('Adaptation', 'Track'),
        meta: {
          section: 'adaptation'
        },
      },
      {
        path: '/vacations',
        name: 'vacations',
        component: viewModule('Vacations'),
        meta: {
          section: 'vacations'
        },
      },
      {
        path: '/help/',
        name: 'help',
        component: viewModule('SinglePages','Help'),
        meta: {
          section: 'help'
        },
      },
      {
        path: '/404',
        name: 'NotFound',
        component: viewModule('SinglePages','404'),
      },
      {
        path: '/*',
        name: 'NotFound',
        component: viewModule('SinglePages','404'),
      },
    ],
  },

]
