export default {
  // Общие строки для всех разделов
  common: {
    menu_title: 'Меню',
    menu: {
      notifications: 'Уведомления',
      wall: 'Стена',
      address: 'Адресная книга',
      profile: 'Личный кабинет',
      groups: 'Группы',
      calendar: 'Мероприятия',
      knowl_base: 'База знаний',
      vacancies: 'Вакансии',
      services: 'Сервисы',
      polls: 'Опросы',
      chat: 'Чаты',
      structures: 'Структура',
      no_silent: 'Не молчи',
      ideas: 'Предложить идею',
      employees: 'Администрирование',
      clients: 'Клиенты',
      admin: 'Админ-панель',
      shop: 'Магазин',
      task_tracker: 'Доска задач',
      adaptation: 'Адаптация',
      vacations: 'Отпуски',
    },
    error: 'Ошибка',
    errors: {
      common: 'Произошла ошибка',
      file_upload: 'Ошибка загрузки файла',
      file_dwn: 'Ошибка при скачивании',
      file_save: 'Ошибка при сохранении',
      no_bucket: 'Нет бакета с именем',
      file_size: 'Ошибка: размер файла превышен',
      file_del: 'Ошибка при удалении файла',
      files_del: 'Ошибка при удалении файлов',
      file_size2: 'Файлы больше {size} прикладывать нельзя',
      user_not_found: 'Пользователь не найден',
      file_not_found: 'Файл не найден или был удален',
      user_change: 'Ошибка изменения пользователей',
      sending: 'Ошибка при отправке',
      fill: 'Ошибка в заполнении',
      required: 'Поле не заполнено',
      data: 'Ошибка в данных',
      data_full: 'При проверке данных обнаружены ошибки',
      no_val: 'Не указано',
      title: 'Не указан заголовок',
      no_access: 'Нет доступа',
      img_size: 'Неверный размер',
      empty_fields: 'Не заполнены некоторые поля данных',
      bucket_limit: 'Достигнут предел хранилища ({size})! Доступно {available}',
      no_komment_owner_kind: 'Не указан тип владельца ленты комментариев',
    },
    dates: {
      today: 'Сегодня',
      yesterday: 'Вчера',
      tomorrow: 'Завтра',
      in: 'Через',
      ago: 'назад',
      until: 'До',
      years: "0 лет | {count} год | {count} года | {count} лет ",
      months: "0 месяцев | {count} месяц | {count} месяца | {count} месяцев",
      days: "0 дней | {count} день | {count} дня | {count} дней",
      weeks: "0 недель | {count} неделя | {count} недели | {count} недель",
      hours: '0 часов | {count} час | {count} часа | {count} часов',
      minutes: '0 минут | {count} минута | {count} минуты | {count} минут',
      seconds: '0 секунд | {count} секунда | {count} секунды | {count} секунд',
      less_than_month: 'меньше месяца'
    },
    dates_f: {
      years: "0 | год | года | лет ",
      months: "0 | месяц | месяца | месяцев",
      days: "нет | день | дня | дней",
      weeks: "0 | неделя | недели | недель",
      hours: '0 | час | часа | часов',
      minutes: '0 | минута | минуты | минут',
      seconds: '0 | секунда | секунды | секунд',
    },
    time: {
      M: 'мес',
      d: 'дн',
      h: 'ч',
      m: 'мин',
      s: 'сек',
    },
    time_f: {
      M: 'месяцев',
      d: 'дней',
      h: 'часов',
      m: 'минут',
      s: 'секунд',
    },
    begin: 'Начало',
    end: 'Окончание',
    select: 'Выбрать',
    select_many: 'Выбрать несколько',
    delete: 'Удалить',
    edit: 'Редактировать',
    restore: 'Восстановить',
    add: 'Добавить',
    create: 'Создать',
    change: 'Изменить',
    update: 'Обновить',
    save: 'Сохранить',
    rename: 'Переименовать',
    replace: 'Переместить',
    send: 'Отправить',
    edit_rights: 'Настроить права доступа',
    apply: 'Применить',
    download: 'Скачать',
    export: 'Выгрузить',
    cancel: 'Отменить',
    canceling: 'Отмена',
    yes: 'Да',
    no: 'Нет',
    good: 'Хорошо',
    turn_on: 'Включить', 
    turn_off: 'Выключить',
    back: 'Назад',
    file_loading: 'Загрузка файла',
    file_deleted: 'Файл удален',
    files_deleted: 'Все файлы удалены',
    find: 'Искать',
    flush: 'Сбросить',
    open: 'Открыть',
    close: 'Закрыть',
    show: 'Показать',
    load: 'Загрузить',
    empty_m: 'Не указан',
    empty_f: 'Не указана',
    confirm: 'Подтвердить',
    i_confirm: 'Подтверждаю',
    extra_info: 'Дополнительная информация',
    link: 'Ссылка',
    title: 'Заголовок',
    comment: 'Комментарий',
    description: 'Описание',
    save_changes: 'Сохранить изменения',
    saved: 'Сохранено',
    delete_all: 'Удалить все',
    show_all: 'Показать все',
    expand: "Развернуть",
    collapse: 'Свернуть',
    uploader_text: 'Прикрепите и перетащите файлы сюда',
    uploader_info: 'Любой тип файлов до 100 мегабайт',
    file_too_big: 'Размер файла слишком большой',
    file_up: 'Файл загружен',
    file_uploaded_succ: '{file} загружен успешно',
    file_upload_fail: '{file} не загружен',
    adjust_image: 'Загрузите и настройте картинку',
    enter_text: 'Введите текст',
    fields_not_filled: 'Не заполнены поля',
    recievers: 'Получатели',
    data_sent: 'Данные отправлены',
    data_upd: 'Данные обновлены',
    data_ch: 'Данные изменены успешно',
    data_saved: 'Данные сохранены успешно',
    mails_sent_to_users: 'Письма отправлены указанным пользователям',
    people: 'чел.',
    sel_many: 'Выбраны',
    members_pl: 'никого | участник | участника | участников',
    pages: 'Всего {total} страниц',
    confirm_del: 'Подтверждаете удаление?',
    confirm_cancel: 'Подтверждаете отмену?',
    insert_url: 'Вставьте ссылку',
    not_found: 'Не найдено',
    empty_here: 'Тут пока ничего нет',
    unsaved_files: 'Несохраненные файлы',
    ph: {
      title: 'Введите название',
      date: 'Введите дату',
      num: 'Введите число',
      str: 'Введите строку',
      url: 'Введите URL',
    },
    posts: {
      title: 'Создать публикацию',
      wall_company_news: 'Новость компании',
      thanking: 'Пост-благодарность',
      thanking_hint: 'Введите минимум 30 символов и обязательно <br> упомяните сотрудника, которого хотите поблагодарить',
      ext_editor: 'Расширенный редактор',
      send_hint: 'Отправка сообщения: Ctrl+ENTER, перенос строки: Shift+ENTER',
      append_file: 'Прикрепить',
      append_files: 'Прикрепить файлы',
      file_size_hint: 'Файл не должен превышать 100 МБ',
      attachments: 'Вложения',
      media: 'Изображения и видео',
      documents: 'Документы и прочие файлы',
      ph_thanking: 'Упомяните того, кого ходите отблагодарить',
      ph_text: 'Напишите текст сообщения. Используйте @, чтобы кого-то упомянуть',
      message: 'Сообщение',
      send: 'Опубликовать',
      like: 'Нравится',
      dislike: 'Не нравится',
      comments: 'Комментарии',
      comment: 'Комментировать | {n} комментарий | {n} комментария | {n} комментариев',
      add_comment: 'Добавить комментарий',
      show_all: 'Показать все комментарии',
      show_more: 'Комментариев нет | Показать еще {n} комментарий | Показать еще {n} комментария | Показать еще {n} комментариев',
      answer: 'Ответить',
      more_act: 'Ещё',
      hide: 'Скрыть ответы',
      show: 'Нет ответов | Показать {count} ответ | Показать {count} ответа | Показать {count} ответов',
      created: 'Пост создан:',
      deleted: 'Пост удален',
      more: 'Подробнее',
      share: 'Поделиться',
      copy: 'Скопировать ссылку',
      link_copied: 'Ссылка скопирована',
      link_not_copied: 'Ссылка не скопирована',
      post_actions: 'Действия с постом',
      comment_actions: 'Действия с комментарием',
      mentioned_empls: 'Упомянутые сотрудники: ',
      confirm_comment_del: "Подтверждаете удаление комментария?",
      comment_deleted: 'Комментарий удален',
      input_more30: 'Введите больше 30 символов',
      choose_person_to_thank: 'Укажите в посте одного человека, кому предназначена благодарность',
      system_msg: 'Системное оповещение',
      edited: 'Отредактировано',
      and_yet: 'и еще',
      show_comments: 'Развернуть комментарии (еще {len})',
      no_comments: 'Скрыть комментарии',
      hide_comments: 'Свернуть комментарии',
      edit_post: 'Редактировать пост',
      editing_post: 'Редактирование поста',
      edit_comment: 'Редактировать комментарий',
      ch_files: 'Изменить файлы',
      text_err: 'Не указан текст сообщения',
      err_size: 'Файлы больше 20 МБ нельзя прикладывать к постам',
      load_more: 'Загрузить ещё...',
      back_to: {
        user: 'Назад в блог сотрудника',
        feed: 'Назад в ленту',
        company: 'Назад на стену компании',
        group: 'Назад в группу',
      },
      emoji: {
        search: "Поиск",
        notfound: "Смайлы не найдены",
        categories: {
          search: "Результаты поиска",
          recent: "Часто используемые",
          smileys: "Смайлы",
          people: "Люди",
          nature: "Животные и природа",
          foods: "Еда и напитки",
          activity: "Активности",
          places: "Путешествия",
          objects: "Строения",
          symbols: "Символы",
          flags: "Флаги",
          custom: "Кастом"
        }
      },
      mentions_no_data: 'Введите имя или фамилию пользователя'
    },
    list: {
      find_by_fullname: 'Поиск по ФИО',
      no_position: 'нет должности',
      no_selected: 'Никто не выбран',
    },
    login_bar: {
      show_msg_in_console: 'Показывать сообщения в консоли:',
      logout: 'Выйти',
    },
    notifs: {
      group: {
        new: 'Создана новая группа',
      },
      empl: {
        new: 'Добавлен новый сотрудник'
      },
      feed: {
        need_update: 'Нужно обновить ленту',
      },
      post: {
        blog_new: 'Новый пост в блоге',
        blog_del: 'Пост в блоге удален',
        blog_edited: 'Изменен пост в блоге',
        company_new: 'Вышла новость по компании',
        company_edited: 'Новость по компании изменена',
        feed_new: 'Новый пост в ленте новостей',
        feed_edited: 'Изменен пост в ленте новостей',
        group_new: 'Новый пост в группе',
        group_del: 'Пост в группе удален',
        group_edited: 'Изменен пост в группе',
        descr_news: 'Проверьте ленту новостей',
        descr_feed: 'Проверьте вашу ленту событий',
        descr_blog: 'Проверьте блог сотрудника ',
        descr_group: 'Проверьте группу ',
      },
      data_copied: 'Данные скопированы',
    },
    mood: {
      title: 'Как ваше настроение?',
      rate: 'Оцените ваше настроение от 1 до 10 в момент прихода на работу',
      thanks: 'Спасибо! Данные о настроении учтены!',
      filename: 'Настроение сотрудников в {name} за {begin}-{end}',
      excel_sheet: 'Настроение сотрудников',
      err_excel: 'Ошибка при экспорте данных настроений в Excel'
    },
    tags: {
      title: 'Метки (теги)',
      add: 'Добавить тег',
      new: 'Новый тег',
      create: 'Создать новую метку',
      del_confirm: 'Удалить тег во всех объектах?'
    },
    e404: 'Упс, кажется эта страница больше не актуальна. Возможно изменился ее адрес или объект не существует',
    to_main_page: 'На главную',
    no_recipient_yet: 'Пока ни один из сотрудников не назначен...',
    one_select: 'Выберите значение',
    select_date: 'Выберите дату',
    list_select: 'Выберите из списка',
    empl_select: 'Выберите сотрудников',
    loading: 'Загружается...',
    code_copied: 'Код скопирован',
    footer: {
      tech_support: 'Техническая поддержка',
    },
    cover_size: 'Загрузите обложку с шириной 3420px и высотой от 250px в формате png или jpg'
  },
  login: {
    title: 'Войти',
    ws: 'Код компании',
    copy: 'Все права защищены',
    site: 'Основной сайт',
    policy: 'Политика конфедициальности',
    agree: 'Принимаю',
    terms: 'Условия использования',
    required: 'Это поле обязательно',
    no_email: 'Пользователь с таким email не зарегистрирован',
    pwd: 'Пароль',
    pwd2: 'Подтверждение пароля',
    pwd_err: 'Пароль неверный',
    auth: 'Войти в систему',
    agreement1: 'Заходя в приложение вы автоматически соглашаетесь с',
    agreement2: 'пользовательским соглашением',
    pers_agreement: 'и даете согласие',
    pers_agr: 'на обработку персональных данных',
    forgot: 'Забыли пароль?',
    lang: 'Сменить язык',
    err: {
      enter: 'Ошибка входа',
      no_login_pwd: 'Не указано имя или пароль',
      user_del: 'Пользователь удален',
      client_blocked: 'Компания заблокирована',
      server: 'Ошибка на сервере',
      data: 'Ошибка при проверке',
      restore: 'Ошибка восстановления пароля',
      pwd_change: 'Ошибка при изменении пароля',
      data_send: 'Ошибка при отправке данных',
      pwd_equal: 'Убедитесь, что пароли совпадают',
    },
    restore_title: 'Восстановление пароля',
    restore_sub: 'Мы вышлем ссылки для сброса пароля на вашу электронную почту',
    restore_ok: 'Проверьте почту и перейдите по ссылке для смены пароля',
    letter_sent: 'Письмо отправлено',
    back: 'Вернуться назад',
    reset: 'Сброс пароля',
    reset_ok: 'Пароль изменен',
    reset_msg: 'Новый пароль успешно установлен'
  },
  // таблицы
  tbl: {
    // колонки
    cols: {
      // общие для многих таблиц
      common: {
        name: 'Название',
        descr: 'Описание',
        date: 'Дата',
        time: 'Время',
      },
      admin: {

      },
    },
  },
  grant_access: {
    title: 'Предоставить доступ',
    btn: 'Выбрать права',
    all: 'Все сотрудники',
    all_heads: 'Все руководители',
    read_level: 'Чтение',
    write_level: 'Запись',
    no_level: 'Недоступно',
    all: 'Доступно всем',
    all_mngrs: 'Все руководители',
    types: {
      for_all: {
        title: 'Доступен всем',
        desc: 'Все сотрудники компании'
      },
      search_by_head: {
        title: 'Поиск по руководителям',
        desc: 'Только руководители отделов',
        search_placeholder: 'Введите ФИО'
      },
      search_by_name: {
        title: 'Поиск по имени и должности',
        desc: 'Если вы хотите добавить кого-то конкретно',
        search_placeholder: 'Введите ФИО, должность'
      },
      search_by_department: {
        title: 'По отделам',
        desc: 'Доступ будет у всех сотрудников отделов',
        search_placeholder: 'Введите название отдела',
      },
      search_by_city: {
        title: 'По городам',
        desc: 'Выберите конкретный филиал из структуры',
        search_placeholder: 'Укажите город',
      },
      search_by_time: {
        title: 'По времени работы',
        desc: 'Сортировка по времени работы',
        search_placeholder: 'Укажите время',
      },
      manual_search: {
        title: 'Ручная фильтрация',
        desc: 'Подберите фильтры под себя',
        search_placeholder: 'Укажите данные вручную',
      },
    },
    save: 'Предоставить доступ',
    set_ok: 'Права установлены успешно',
    error: 'Ошибка прав',
    depts: 'Отделы',
    nothing_selected: 'Ничего не выбрано, нужно выбрать какие-то права',
  },
  // Стена компании
  company: {
    feed: {
      company_feed: 'Новости компании',
      thank: 'Благодарность',
      user_feed: 'Лента событий',
      type_unknown: 'Неопознанный вид события',
      tooltip_user: 'Посты в группах и блогах, на которые вы подписаны. Уведомления о днях рождения, приглашения на мероприятия и другие новости',
      tooltip_company: 'Только важные новости компании',
      bd_today: 'Сегодня день рождения празднуют'
    },
    mention: {
      in: 'Упоминание в {title}',
      group: 'Упоминание в группе',
      blog: 'Упоминание в блоге',
      comment_answer_of: 'Упоминание в ответе на комментарий к',
      post_in: 'посту в',
      del_post: 'удаленному посту',
      comment_news: 'Упоминание в комментарии к новости по компании',
      other: 'Другое упоминание',
      text: 'Текст сообщения'
    },
    comments: {
      i_new_empl: 'Я - новый сотрудник',
      blog_post: 'Комментарий поста в блоге',
      feed_post: 'Комментарий поста в ленте',
      group_post: 'Комментарий поста в группе',
      blog_answer: 'Ответ на ваш комментарий в блоге',
      type_not_defined: 'Тип записи с комментарием не определен'
    },
    title: {
      news_comment: 'комментарии к новости',
      post_comment: 'комментарии к посту',
      news: 'новости по компании',
      group_comment: 'комментарии в группе',
      blog_comment: 'комментарии в блоге',
      feed_post: 'посте на стене',
      feed_post_comment: 'комментарии к посту на стене',
    },
    places: {
      your_blog: 'в вашем блоге',
      post_del: 'удаленном посте',
      news: 'новостях компании',
      user_blog: 'в блоге сотрудника {name}',
      group: 'в группе {name}',
      feed: 'общей ленте',
    },
    msg: {
      group_new: 'создал новую группу',
      group_del: 'удалил группу',
      group_edit: 'изменил данные группы',
      title: {
        new_group: 'Новая группа',
        del_group: 'Группа удалена',
        new_empl: 'Новый сотрудник'
      }
    },
    notifs: {
      new_group: 'Создал новую группу',
      del_group: 'Удалил группу',
      new_empl: 'Приветствуем нового сотрудника',
      add_post: ' добавил пост ',
      post_in_blog: 'в своем блоге ',
      post_in_group: 'в группе ',
      post_in_feed: 'в ленте ',
    },
    widgets: {
      thanks: {
        title: 'БЛАГОДАРНОСТИ',
        descr: 'Сотрудники, которые чаще всех получали благодарности',
        no: 'Благодарности пока не оставлены. Вы можете тегнуть коллегу в посте-благодарности',
      },
      bdays: {
        title: 'Дни рождения',
        today: 'Сегодня!',
        today_more: 'Еще у {n} сегодня',
        month_more: 'Предстоит еще {n} в этом месяце',
        descr: 'Сотрудники, празднующие день рождения сегодня или в ближайшие даты',
        no: 'Сегодня никто не празднует день рождения',
        congr: 'Поздравить',
        congrats: 'Поздравляю, тебя @{name} с днем рождения! 🥳🎁🎈',
        next: 'Все дни рождения',
      },
      groups: {
        title: 'Важное в группах',
        descr: 'Посты или другая важная информация в группах',
        no: 'Пока нет ничего важного в группах',
      },
      events: {
        title: 'Календарь',
        descr: 'События или мероприятия, назначенные на сегодня',
        no: 'В календаре нет событий на сегодня',
      },
      kb: {
        title: 'Важное в базе знаний',
        descr: 'Важные статьи или файлы в базе знаний',
        no: 'В базе знаний пока нет важной информации',
      },
      polls: {
        title: 'Важное в опросах',
        descr: 'Важные опросы мнения для сотрудников',
        no: 'Сейчас нет опросов',
      },
      blogs:{
        title: 'Важное в блогах',
        descr: 'Посты в блогах, отмеченные как важные',
        no: 'Сейчас нет важной информации в блогах',
      },
      vacancies: {
        title: 'Актуальные вакансии',
        descr: 'Вакансии по компании, которые сейчас открыты',
        no: 'Пока в компании нет вакансий',
      },
      other: {
        title: 'Важное',
        descr: 'Прочая важная информация',
        no: 'Важной информации пока нет',
      },
    },
    welcome: 'добро пожаловать в команду!',
  },
  // Админская панель
  admin_panel: {
    title: 'Панель администратора',
    tabs: {
      all_data: 'Все данные',
      mail: 'Проверка почты',
      notif_settings: 'Настройки оповещений',
      clean: 'Очистка файлов',
      logs: 'Логи',
      service: 'Сервисная панель'
    },
    tbl: {
      data_mng: {
        collection: 'Коллекция',
        docs_count: 'Число записей',
        actions: 'Действия',
      },
      posts: {
        _id: '№',
        message: 'Текст',
        author_ref: 'Автор',
        created: 'Дата/время',
        parent: 'Родитель',
      }, 
      users: {
        _id: '№',
        fullName: 'ФИО',
        _email: 'Эл. почта',
        roles: 'Роли',
      },
      actions: {
        actions: 'Действия'
      },
      notifs: {
        fullName: 'ФИО',
        addUser: 'Добавление пользователя',
        publications: 'Публикации',
        mentions: 'Упоминания',
        subscribe: 'Подписки',
        actions: 'Операции',
      },
      cleaning: {
        filename: 'Имя файла',
        name: 'Путь',
        lastModified: 'Дата изменения',
        size: 'Размер',
      },
      logs: {
        date: 'Дата/Время',
        job_code: 'Код события',
        message: 'Сообщение',
      },
    },
    tables: {
      users: 'Пользователи',
      groups: 'Группы',
      posts: 'Посты',
      messages: 'Сообщения',
      events: 'События',
    },
    done: 'Выполнено',
    no_tbl_sel: 'Таблица не выбрана',
    client_data_mng: 'Управление данными клиента',
    test_data_notif: 'Данная страница предназначена для тестовых данных, не для реальных клиентов!',
    del_data: 'Удалить данные',
    del_data_filter: 'Удалить по фильтру',
    del_coll: 'Удаление коллекции ',
    del_confirm: 'Данные будут удалены безвозвратно, удалить?',
    data_deleted: 'Данные в коллекции удалены: ',
    data_del_err: 'Ошибка при удалении данных',
    mail: {
      header: 'Тестовая отправка письма на ящики',
      test: 'Тест почты',
      not_sel: 'Не выбран e-mail',
      need_sel: 'Надо выбрать как минимум один адрес',
      sent: 'Почта отправлена',
      check: 'Проверьте почту',
      error: 'Отправка не удалась',
      sendsay_send: 'Проверка SendSay',
      template_send: 'Проверка html-tmpl',
    },
    cleaning: {
      check: 'Проверить файлы',
      del: 'Удалить файлы',
      hint: 'Внимание! Проверяются и удаляются файлы всех клиентов, не только текущего воркспейса',
      deleted: 'Удалено {deleted} файлов',
      error: 'Ошибка при удалении файлов',
    },
    service: {
      polls_fix: 'Обновить опросы',
      articles_fix: 'Установить права',
      articles_fixed: 'Права на статьи (папки, вакансии) установлены «Для всех»',
      service_op: 'Выполнить операцию',
    },
    jobs: {
      confirm_del: 'Удалить задание?',
    }
  },
  // Адресная книга
  address: {
    title: 'Адресная книга',
    title_R: 'адресной книге',
    no_empls: 'Сотрудники не найдены',
    enter_search: 'Введите слово для поиска',
    filter_by: 'Отбор по колонке',
    empty: 'Сейчас ваша адресная книга пуста, но скоро в ней появятся контакты ваших коллег',
    tbl: {
      empls: {
        name: 'ФИО сотрудника',
        positions: 'Должность',
        phone: 'Телефон',
        email: 'Почта',
        departments: 'Подразделение',
        cabinet: 'Кабинет',
        actions: 'Действия',
      },
    },
    create_chat: 'Создать чат',
    pin_user: 'Закрепить сотрудника',
    unpin_user: 'Открепить сотрудника',
    pin_move_user: 'Переместить позицию',
    select_new: 'Выберите новую позицию',
  },
  // Профиль сотрудника
  profile: {
    dept_adm: 'Административное подразделение',
    dept_func: 'Функциональное подразделение',
    dept_prj: 'Проектное подразделение',
    depts: 'Подразделения сотрудника',
    my_subscribers: 'Мои подписчики',
    subscribers: 'нет подписчиков | {count} подписчик | {count} подписчика | {count} подписчиков',
    subscribe: 'Подписаться',
    subscribed: 'Уже подписаны',
    unsubscribe: 'Отписаться',
    new_subscribe: 'Новый подписчик',
    no_subscribers: 'Пока нет подписчиков, посмотртите всех сотрудников компании в ',
    tabs: {
      main: 'Основное',
      extra: 'Дополнительно',
      soc_nets: 'Социальные сети',
      for_hr: 'Кадровые данные',
      files_hr: 'Файлы HR',
      profile: 'Контакты',
      notifs: 'Уведомления',
      subscribers: 'Подписчики',
    },
    blog: 'Блог',
    edit_empl: 'Редактировать пользователя',
    no_position: 'Должность не указана',
    no_info: 'Информация о себе не заполнена',
    edit_data: 'Редактировать профиль',
    editing: 'Редактирование профиля',
    set_status: 'Добавить статус',
    add_status_title: 'Добавление статуса',
    status_ph: 'Введите текст статуса',
    chat: 'Написать сообщение в чат',
    contacts: 'Контакты',
    int_phone: 'Внутренний телефон',
    no_add_data: 'Нет дополнительных данных',
    manager: 'Руководитель',
    managers: 'Руководители',
    no_manager: 'Руководитель отдела не назначен',
    empls: 'Подчиненные сотрудники',
    colleagues: 'Коллеги',
    status_set: 'Статус успешно установлен',
    open: 'Перейти в профиль',
    birthday: 'День рождения: {date}',
    no_birthday: 'День рождения не указан',
    working_in_company: 'В компании',
    and_more: 'И еще {len} человек',
    no_location: 'Локация не определена',
    load_pic: 'Загрузить обложку',
    del_pic: 'Удалить обложку',
    pic_change: 'Изменить фото профиля',
    pic_updated: 'Фото изменено',
    reset_to_hrm: 'Сбросить данные',
    reset_hrm_confirm: 'Вы действительно хотите сбросить данные, введенные вручную?',
    reset_hint: 'Удалить измененные данные и показывать данные из ЗУП',
    data_has_diffs: 'Данные отличаются от загруженных из 1С ЗУП',
    reset_end_ok: 'Данные сотрудника возвращены к данным из 1С',
    email_copied: 'Email скопирован',
    genders: {
      man: 'Мужской',
      woman: 'Женский',
      not_selected: 'Не указан',
    },
    woman_decret: 'Сотрудница в декрете',
    edit: {
      name: 'Имя',
      surname: 'Фамилия',
      midname: 'Отчество',
      position: 'Должность',
      vac_days: 'Остаток отпуска',
      position_ph: 'Менеджер',
      empl_chief: 'Руководитель',
      cabinet: 'Кабинет',
      email: 'Почта',
      phone: 'Номер телефона',
      lang: 'Язык',
      change_ava: 'Изменить аватарку',
      since: 'В компании с',
      city: 'Город',
      gender: 'Пол',
    },
    hints: {
      name: 'Введите имя',
      surname: 'Введите фамилию',
      midname: 'Введите отчество',
      email: 'Укажите E-mail!',
      cabinet: 'Укажите кабинет',
      position: 'Укажите должность',
      vac_days: 'Число дней',
      correct_email: 'Укажите свой e-mail!',
      phone: 'Ваш номер телефона',
      lang: 'Выберите язык',
      gender: 'Укажите пол',
    },
  },
  // Группы
  groups: {
    title: 'Группы',
    group: 'группа',
    only_mine: 'Только мои группы',
    feed: 'Лента',
    about: 'О группе',
    create: 'Создать группу',
    ph_name: 'Книжный клуб',
    ph_descr: 'Собираемся и обсуждаем любимые книги и авторов',
    join: 'Вступить',
    reject: 'Отклонить',
    accept: 'Принять',
    quit: 'Выйти',
    approve: 'Одобрить',
    edit: 'Редактировать группу',
    empty: 'На данный момент у вас нет ни одной рабочей группы. Создайте собственную группу или дождитесь приглашения от сотрудников',
    no_access: 'Подайте заявку на вступление в группу, чтобы смотреть и делать публикации',
    not_found: 'Группы не найдены',
    not_found_one: 'Группа не найдена',
    deleted: 'Группа удалена',
    deleted_gr: 'Группа {group} была удалена',
    deleting_from: 'Удаление из группы',
    deleted_you: 'Вы были удалены из группы «{name}»',
    deleted_other: 'Участник {who} покинул группу «{name}»',
    approving: 'Одобрение запроса',
    rejecting: 'Отклонение запроса',
    approved_you: 'Ваш запрос в группу {name} был одобрен',
    rejected_you: 'Ваш запрос в группу {name} был отклонен',
    select_users: 'Выберите пользователей',
    choose_new_owner: 'Выберите нового владельца группы',
    noempls_for_change: 'Нет сотрудников для замены',
    owner_changed: 'Владелец группы изменен',
    make_admin: 'Добавить или снять роль администратора',
    new_owner: 'Новый владелец группы',
    you_owner: 'Вы администратор группы',
    is_admin: 'Администратор группы',
    you_are_owner: 'Вы стали администратором группы {name}',
    owner_error: 'Ошибка при установке владельца группы',
    only_1: 'В группе всего 1 участник',
    owner_change_cannot: 'Невозможно изменить владельца',
    added: 'добавлен в группу',
    joined: ' вступил в группу ',
    actions: 'Действия с группой',
    members: 'Участники',
    q_delete: 'Удалить {fio} из группы?',
    confirm_deleting: 'Подтверите удаление',
    confirm_deleting2: 'Подтверите удаление группы',
    del_not_found: '<Группа удалена или не найдена>',
    invite: 'Пригласить',
    invite_empl: 'Пригласить пользователя',
    invite_sent: 'Приглашение отправлено',
    inviting: 'Приглашение',
    inviting_group: 'Приглашение в группу',
    you_invited: 'Вас приглашают в группу {name}. Ссылка для отправлена на почту',
    to_group: 'в группу',
    set_owner: 'Сделать владельцем',
    user_will_be_notified: 'Пользователь получит сообщение в ленте',
    change_owner: 'Изменить владельца',
    request: 'Заявка на вступление в группу',
    requests: 'Заявки',
    req_created: 'Заявка на вступление создана',
    req_removed: 'Заявка на вступление удалена',
    request_exists: 'Заявка уже подана или вы уже в группе',
    make_request: 'Подать заявку',
    cancel_request: 'Отменить заявку',
    request_rejected: 'Запрос отклонен',
    request_rejected_descr: 'Вступление в группу отклонено',
    rejected: 'Приглашение в группу отклонено',
    rejected_full: '{user} отклонил приглашение в группу {group}',
    type: 'Тип',
    type_public: 'Открытая',
    type_closed: 'Закрытая',
    type_private: 'Приватная',
    type_public_descr: 'Любой пользователь может найти группу через поиск или в общем списке, просматривать публикации и вступить в сообщество',
    type_closed_descr: 'Любой пользователь может найти группу через поиск или в общем списке. Вступить и просматривать публикации можно только по приглашению или после одобрения заявки администратором группы',
    type_private_descr: 'Найти группу в поиске или в общем списке могут только ее участники. Вступить можно только по приглашению администратора группы.',
    request_made: 'Заявка создана',
    request_approved: 'Запрос одобрен',
    request_approved_descr: 'Вступление в группу одобрено',
    request_made_descr: 'Заявка на вступление в группу создана',
    made_request: 'подал заявку на вступление в группу',
    you_have_invite: 'У вас есть приглашение в данную группу. Выберите действие:',
    you_in: 'Вы вступили в группу',
    new_member: 'Новый участник группы',
    new_member_full: 'В группе {name} новый участник: {user}',
    posted_in: 'добавил запись в группе ',
    rules: {
      name: 'Название не может быть пустым!',
      name_max: 'Максимальная длина заголовка - 50 символов',
      descr: 'Описание не может быть пустым!',
      descr_max: 'Максимальная длина описания - 250 символов',
      type: 'Тип группы не может быть пустым!'
    },
    errors: {
      creating: 'Ошибка создания группы', 
      client: 'Ошибка проверки клиента',
      client_descr: 'Не удалось установить текущего клиента',
      data_descr: 'Некоторые поля заполнены некорректно',
      valid: 'Ошибка при валидации',
      request: 'Ошибка при одобрении заявки',
      reject: 'Ошибка при отклонении заявки',
    },
    notifs: {
      new_user: 'В группе {group} новый участник: {userFio}',
      no_access: 'У вас нет доступа в группу {name}'
    },
  },
  // Календарь
  cal: {
    title: 'Календарь',
    year: 'Год',
    month: 'Месяц',
    week: 'Неделя',
    day: 'День',
    wd: {
      mo: 'Пн',
      tu: 'Вт',
      we: 'Ср',
      th: 'Чт',
      fr: 'Пт',
      sa: 'Сб',
      su: 'Вс',
    },
    event: 'Событие',
    create: 'Создать событие',
    new: 'Новое событие',
    ph_name: 'Еженеделья встреча топ-менеджеров',
    moving: 'Перенос события',
    changing: 'Изменение события',
    new_cal: 'Новый календарь',
    not_found: 'Событие не найдено',
    confirm_delete: 'Подтверите удаление события',
    no_events_today: 'Сегодня встреч нет',
    events_today: 'Событий сегодня',
    time_left: 'До события',
    go_to_scheduler: 'Перейти в календарь',
    delete: 'Отмена события',
    del_one: 'Удалить одно',
    del_all: 'Удалить все',
    del_select: 'Выберите вариант удаления',
    del_select_full: 'Выберите, удалить одно событие или все повторяющиеся',
    del: 'Событие успешно удалено',
    deleted: 'Событие <b>{name}</b> удалено',
    err_del: 'Ошибка при удалении события',
    cant_create: 'Вы не можете создавать события',
    err_date_lost: 'Нельзя создать событие в прошедшую дату',
    new_user: 'Приветствуем нового сотрудника компании: ',
    time_changed: 'Время события <b>{name}</b> изменено',
    f: {
      name: 'Название события',
      date: 'Дата события',
      date2: 'Дата проведения:',
      start: 'Начало в:',
      end: 'Окончание в:',
    },
    periods: {
      day: 'каждый день',
      week: 'каждую неделю',
      month: 'каждый месяц',
      year: 'каждый год',
    },
    author_label: 'Организатор:',
    description: 'Описание',
    date_time: 'Дата и время события',
    start_at: 'Дата начала',
    end_at: 'Окончание',
    time_begins: 'Время начала',
    time_finish: 'Время окончания',
    event_changed: `Событие <b>{name}</b> перенесено.<br/>
    Старое время - c {fromBegin} по {fromEnd} ({fromDate})<br/>
    Новое время - с {toBegin} по {toEnd} ({toDate})`,
    color: 'Цвет',
    time_from: 'Время с',
    time_to: 'по',
    is_repeat: 'Повторяющееся событие',
    by_week: 'По дням недели: ',
    every: 'Каждый: ',
    end: 'Заканчивается:',
    after: 'после',
    one_time: 'раз',
    invite: 'Пригласить других участников',
    members: 'Участники события',
    empls: 'Сотрудники',
    zoom: 'Ссылка на веб-конференцию',
    you_are_participant: 'Вас указали в списке участников',
    of_periodical: 'повторяющегося',
    of_event: 'события',
    members_pl: 'ни одного | {count} участник | {count} участника | {count} участников',
    rules: {
      name: 'Название не может быть пустым!',
      name_max: 'Максимальная длина заголовка - 100 символов',
      name_too_short: 'Слишком короткое название, введите больше 5 символов',
      date: 'Нужно указать дату',
      date_past: 'Дата не может быть в прошлом',
      time: 'Нужно указать время',
      time_lost: 'Выбранное время сегодня уже прошло',
      time_err: 'Неверное время окончания',
      members: 'Нужно выбрать участников',
      url: 'Неверный формат ссылки',
      comment_max: 'Превышено количество символов',
      repeat: {
        no: 'Не указан период повторения',
        wdays: 'Не указаны дни недели для повторений',
        end: 'Не указана дата завершения повторений',
        count: 'Не указано число повторений события',
        more1: 'Укажите больше одного повторения'
      },
    },
    notifs: {
      min_10: 'До события осталось меньше 10 минут'
    },
    copy_link: 'Ссылка',
    link_copied: 'Ссылка на событие скопирована',
    link_not_copied: 'Ошибка копирования ссылки на событие',
    periods: {
      this_month: 'В этом месяце',
      last_month: 'В прошлом месяце',
      this_year: 'В этом году',
      last_year: 'В прошлом году',
      this_week: 'На этой неделе',
      last_week: 'На прошлой неделе',
    },
  },
  // База знаний
  kb: {
    title: 'База знаний',
    articles: 'Материалы',
    fav_articles: 'Избранное',
    folders: 'Разделы',
    files: 'Файлы',
    amount: 'Статей',
    no_art: 'Пока ничего нет',
    empty: 'В базе знаний нет ни одного документа. Поделитесь с коллегами своими статьями или ожидайте обновление раздела',
    folder_empty: 'Эта папка пока пустая. Поделитесь с коллегами своими материалами или ожидайте обновления раздела.',
    new_article: 'Новая статья',
    new_vacancy: 'Новая вакансия',
    new_folder: 'Новая папка в базе знаний',
    new_folder_sh: 'Добавить папку',
    new_file: 'Добавить файл в базу знаний',
    new_file_sh: 'Добавить файл',
    add_name: 'Добавьте название',
    think_name: 'Придумайте название',
    ph_descr: 'Расскажите, какую информацию можно найти в Базе знаний',
    folder: 'Выберите папку',
    set_titles: 'Настройка шапки базы знаний',
    change_article: 'Изменить данные статьи',
    change_folder: 'Изменить данные папки',
    change_file: 'Изменить данные файла',
    created_at: 'Дата создания',
    updated_at: 'Дата обновления',
    search_ph: 'Поиск по базе знаний',
    rights: 'Доступы',
    choose_file: 'Выбрать файл',
    attach: 'Прикрепить файл',
    attached_files: 'Прикрепленные файлы',
    uploading: 'Файл загружается',
    drop_media: 'Прикрепить картинку или видео',
    add_cover: 'Добавить обложку',
    del_cover: 'Удалить обложку',
    not_previewable: 'Этот тип файлов невозможно просмотреть',
    show_all: 'Показать все',
    hide_off: 'Свернуть',
    add: 'Добавить статью',
    placeholder: 'Напишите какой-нибудь текст...',
    del: 'Удалить статью полностью?',
    del_warn: 'Статья будет удалена без возможности восстановления',
    deleted: 'Статья была удалена',
    saved: 'Статья сохранена',
    folder_saved: 'Папка сохранена',
    file_saved: 'Файл сохранен',
    copy_link: 'Скопировать ссылку',
    file_link_copied: 'Ссылка на файл скопирована',
    correct: 'Исправить статьи',
    corrected: 'Статьи обновлены',
    corrected_cnt: 'Обновлено {cnt} статей',
    author: 'Автор',
    header: 'Название',
    toc_title: 'Оглавление',
    toc_empty: 'Добавьте в текст заголовки, чтобы увидеть оглавление',
    grants_article_title: 'Доступы к статье',
    grants_folder_title: 'Доступы к папке',
    grants_file_title: 'Доступы к файлу',
    child: 'Дочерние статьи',
    no_preview: 'Просмотр недоступен',
    preview: 'Просмотр файла',
    pdf: 'Скачать как PDF',
    fav: 'Избранное',
    // to_fav: 'В избранное',
    // from_fav: 'Из избранного',
    delete_file: 'Удалить файл?',
    delete_video: 'Удалить загруженое видео?',
    del_file_confirmation: 'Удаление файла',
    del_folder_confirmation: 'Удаление папки',
    del_article_confirmation: 'Удаление статьи',
    del_file_deleting: 'Файл будет удален навсегда. Продолжить?',
    del_folder_deleting: 'Папка и все вложенные объекты будет удалена навсегда. Продолжить?',
    del_article_deleting: 'Статья будет удалена навсегда. Продолжить?',
    delete_files: 'Удалить все файлы?',
    files_att: 'Вложенные файлы',
    filetype_hint: 'Можно добавлять документы MS Office, файлы .PSD, .CDR, .DWG размером не более 100 МБ. Фото и видео можно вставить прямо в тексте статьи',
    rights_title: 'Права на чтение',
    rights_del_hint1: 'Права администратора будут удалены, продолжить?',
    rights_del_hint2: 'Права всех сотрудников будут удалены, продолжить?',
    rights_not_set: 'Просматривать могут все',
    for_admins: 'Для администраторов',
    add_empls: 'Добавить сотрудников',
    select_empls: 'Выберите сотрудников, имеющих право просмотра',
    author_not_found: 'Автор не найден',
    rights_succ: 'Права изменены успешно',
    feedback_sent: 'Обратная связь отправлена',
    user_will_get_mail: 'Пользователь получит сообщение в почте',
    change_folder: 'Изменить папку',
    move_folder: 'Переместить папку',
    no_title: 'Нет заголовка',
    no_subtitle: 'Нет подзаголовка',
    mention_2symbols: 'Введите минимум 2 символа',
    err: {
      no_title: 'Не указан заголовок статьи!',
      no_content: 'Нет содержимого статьи!',
      no_service_group: 'Не указана группа сервисов',
      no_section: 'Не указан раздел!',
      no_access: 'У вас нет доступа к данной странице',
      no_self_replace: 'Нельзя перемещать папку в себя или в текущую папку',
      file_not_media: 'Вы пытаетесь загрузить файл, не являющийся видео или картинкой',
      no_url: 'Нет ссылки на файл',
    },
    new: {
      article: 'Новая статья в базе знаний',
      service: 'Новая страница сервиса',
      vacancy: 'UX-дизайнер',
    },
    sort: {
      az: 'По алфавиту А-Я',
      za: 'По алфавиту Я-А',
      created: 'По дате создания',
      edited: 'По дате редактирования',
      no: 'Без сортировки',
    },
    editor: {
      panel: {
        undo: 'Отменить',
        redo: 'Вернуть',
        bold: 'Утолщенный',
        italic: 'Наклонный',
        underline: 'Подчеркнутый',
        strike: 'Зачеркнутый',
        code: 'Моноширинный',
        code_block: 'Блок кода',
        del_marks: 'Удалить форматирование',
        clear: 'Обычная строка',
        link: 'Вставить ссылку',
        del_link: 'Удалить ссылку',
        emoji: 'Эмодзи',
        media: 'Медиа-файл',
        music: 'Яндекс.Музыка',
        toc: 'Оглавление',
        h1: 'Заголовок 1',
        h2: 'Заголовок 2',
        h3: 'Заголовок 3',
        h4: 'Заголовок 4',
        p: 'Простой текст',
        ul: 'Список',
        ol: 'Нумерованный список',
        quote: 'Цитата',
        left: 'Выравнивание по левому краю',
        center: 'Выравнивание по центру',
        right: 'Выравнивание по правому краю',
        justify: 'Выравнивание по ширине',
        marker: 'Выделение фона цветом',
        task: 'Пункт задачи',
        table: 'Вставить таблицу',
        t_add_column: 'Добавить колонку',
        t_add_column_after: 'Добавить колонку после',
        t_add_row: 'Добавить строку',
        t_add_row_after: 'Добавить строку после',
        t_del_col: 'Удалить колонку',
        t_del_row: 'Удалить строку',
        t_delete: 'Удалить таблицу',
        t_cell_merge: 'Соединить ячейки',
        t_cell_split: 'Разбить ячейку',
        t_header: 'Сделать заголовком первую строку',
        t_col_header: 'Сделать заголовок-колонку',
      },
      ya_music_link_ph: 'Вставьте ссылку на трек',
      err: {
        ya_music_link: 'Некорректная ссылка на Яндекс-музыку',
      }
    }
  },
  // Вакансии
  vac: {
    title: 'Вакансии компании',
    total: 'Всего вакансий',
    new: 'Добавить вакансию',
    empty: 'На данный момент вакансии отсутствуют',
    no: 'Пока еще нет ни одной вакансии',
    not_found: 'Вакансии не найдены',
    saved: 'Вакансия сохранена',
    apply: 'Рекомендовать/Откликнуться',
    salary: 'Зарплата (от, до)',
    salary_from: 'от {val}',
    salary_up: ' до {val}',
    no_salary: 'Не указана',
    response: {
      recievers: 'Получатели откликов',
      recievers_no: 'Получатели не указаны',
      no_receivers_hint: 'Не указаны получатели откликов в компании',
      alert: 'Получатели не указаны, отклики будут приходить всем администраторам',
      sent: 'Вакансия отправлена',
      no_user_to_receive: 'Отклик не был отправлен, так как не определены получатели'
    },
    deleting: 'Удаление вакансии',
    deleting_descr: 'Вакансия <b>{name}</b> будет удалена. Продолжить?',
    deleted: 'Вакансия удалена',
    deleted_succ: 'Вакансия удалена успешно',
    respond_title: 'Отклик на вакансию',
    to_feed: 'В ленте событий',
    feed_post: 'Уважаемые коллеги! <br/>В нашей компании открыта вакансия <a href="/vacancy/{id}" target="_blank">{header}</a><br/>',
    share: 'Скопировать ссылку',
    vac_posted: 'Вакансия опубликована в ленте',
    link_copied: 'Ссылка на вакансию скопирована',
    respond: {
      field1_label: 'Имя отправителя',
      field2_label: 'Текст сопроводительного письма',
      att_cv: 'Приложить резюме',
      no_admins: 'Не найдено ни одного администратора',
    },
    select_tag: 'Выберите тег',
    template: `<h5>Описание вакансии</h5><br/>

<h5>Требования к кандидату</h5><br/>

<h5>Условия</h5><br/>
`,
  },
  // Сервисы
  srv: {
    tabs: {
      all: 'Сервисы',
      my: 'Мои заявки',
      info: 'Информация',
      steps: 'Шаги',
      rights: 'Права',
      table: 'Заявки',
    },
    bp_table: {
      name: 'Название процесса',
      author: 'Автор',
      performer: 'Исполнитель',
      chief: 'Руководитель',
      start: 'Дата начала',
      step: 'Этап',
      deadline_step: 'Дедлайн этапа',
      deadline: 'Общий дедлайн',
      status: 'Статус',
      active: 'Активные',
      finished: 'Завершенные',
      result: 'Статус',
    },
    list: 'Бизнес-процессы',
    my_orders: 'Мои заявки',
    orders_to_me: 'Заявки для согласования',
    open_srv: 'Открыть заявку',
    title: 'Сервисы для сотрудников',
    total: 'Всего сервисов',
    groups: 'Группы сервисов',
    convert: 'Преобразовать старые',
    new: 'Новый бизнес-процесс',
    empty: 'Здесь будут корпоративные сервисы вашей компании',
    new_bp: 'Новый бизнес-процесс',
    edit: 'Редактировать бизнес-процесс',
    edit_bp: 'Изменение бизнес-процесса',
    confirm_del: 'Удалить бизнес-процесс?',
    types: {
      label: 'Тип сервиса',
      simple: 'Простой',
      simple_d: 'С одним этапом',
      many: 'Многоэтапный',
      many_d: 'С несколькими этапами',
    },
    dept: 'Ответственное подразделение',
    no_dept: 'Подразделение не указано',
    no_user: 'Сотрудник не указан',
    no_date: 'Дата не указана',
    no_data: 'Поле не заполнено',
    days_limit_set: 'Установить срок исполнения',
    days_limit: 'Срок исполнения',
    no_limit: 'Срок не установлен',
    for_all: 'Доступен всем',
    for_all_descr: 'Каждый может воспользоватья этим процессом',
    new_group: 'Новый раздел',
    new_group_add: 'Добавить группу сервисов',
    no: 'Пока еще нет ни одного сервиса',
    not_found: 'Сервисы не найдены',
    deleting: 'Удаление сервиса',
    deleting_descr: 'Сервис «{name}» будет удален',
    replace: 'Переместить в другой раздел',
    change: 'Изменить разделы сервисов',
    no_groups: 'Разделов нет',
    add_group: 'Добавить раздел',
    del_group: 'Удалить раздел',
    group_deleted: 'Раздел удален',
    group_name: 'Название группы',
    name: 'Название сервиса',
    group: 'Группа сервиса',
    body: 'Описание сервиса',
    new_group_ph: 'Например: Справки',
    name_ph: 'Например: Справки НДФЛ',
    sg_empty_title: 'Нет названия группы сервисов',
    sg_modal_title: 'Новая группа сервисов',
    sg_modal_edit: 'Изменение группы сервисов',
    saved: 'Сервис сохранен',
    select_group: 'Выберите раздел сервиса',
    select_empl: 'Выберите сотрудника',
    step_employee: 'Ответственный этапа',
    fields: 'Поля процесса',
    steps: {
      start: 'Создание заявки',
      name: 'Название шага',
      fields: 'Поля шага',
      fill_fields: 'Заполните поля',
      new: 'Новый шаг',
      review: 'Ознакомление',
      review_btn: 'Ознакомился',
      new_field: 'Добавить поле',
      field_title: 'Заголовок поля',
      field_value: 'Введите значение',
      field_select: 'Выберите значение',
      empty: 'Пока нет шагов',
      errors: 'Ошибки в настройках шага',
      performer_settings: 'Настройки ответственного на этапе',
      performers: 'Исполнители этапа',
      no_performers: 'Не указаны исполнители этапа',
      initiator: 'Инициатор заявки',
      chief: 'Непосредственный руководитель',
      employee: 'Сотрудник',
      employees: 'Несколько сотрудников',
      set_me: 'Взять на себя',
      set_me_hint: 'Установить себя как единственного исполнителя и согласовать позднее',
      set_alone_succ: 'Теперь вы единственный исполнитель этапа',
      no_more5_empls: 'Нельзя выбирать больше 5 сотрудников',
      not_more10: 'Нельзя создать больше 10 полей на форме',
      no_fields: 'В форме нет ни одного поля',
      no_fieldname: 'Не указано название одного из полей',
      no_formname: 'Не указано название формы',
      no_receivers: 'Не указаны получатели данных',
      default_name: 'Данные для заполнения',
      no_selected: 'Не выбран шаг',
      save: 'Сохранить шаг',
      del: 'Удалить шаг',
      deleting: 'Удаление шага',
      deleting_descr: 'Шаг «{name}» будет удален',
      deleted: 'Шаг удален',
      deleted_succ: 'Шаг удален успешно',
      not_more5: 'Нельзя создать больше 5 шагов',
      saved: 'Шаг сохранен',
      saved_descr: 'Данные шага успешно сохранены',
      open_progress: 'Показать этапы',
      curr_step: 'Текущий этап',
      curr_step_descr: 'Описание этапа',
      fill_fields: 'Заполните поля и утвердите либо отклоните текущий этап заявки',
      accept: 'Согласовать',
      decline: 'Отклонить',
      enter_reason: 'Укажите причину отклонения',
      invalid_action: 'Неверное действие',
      decline_reason: 'Причина отклонения не указана',
      accepted_succ: 'Шаг согласован',
      declined_succ: 'Шаг отклонен',
      rejected: 'Шаг отклонен по причине:',
      field_types: {
        string: 'Строка',
        text: 'Длинный текст',
        number: 'Число',
        date: 'Дата',
        period: 'Период дат',
        select: 'Выбор из списка',
        checkbox: 'Опция "Галка"',
        file: 'Файл',
        dept: 'Подразделение',
        user: 'Выбор сотрудника',
        users: 'Выбор сотрудников',
        phone: 'Телефон',
        email: 'Почта'
      },
    },
    form: {
      form: 'форма',
      title: 'Название формы',
      descr: 'Описание формы',
      new: 'Новая форма',
      new_srv: 'Новая сервисная форма',
      no_forms: 'Пока тут пустовато',
      edit: 'Изменить форму',
      fill: 'Заполнить',
      errors: 'Ошибки при заполнении формы',
      srv_form: 'Сервисная форма «{name}»',
      new_field: 'Добавить поле',
      field_title: 'Заголовок поля',
      field_type: 'Тип поля',
      field_mng: 'Управление',
      field_simple: 'Простое поле',
      field_long: 'Длинный текст',
      field_option: 'Опция (галка)',
      preview: 'Просмотр формы',
      this_user: 'Автор обращения',
      max_str: 'Введена максимально длинная строка (50 символов)',
    },
    err: {
      no_descr: 'Не заполнено описание процесса',
      no_name: 'Не указано название процесса',
      no_group: 'Не выбран раздел сервиса',
      chief_404: 'Не удалось определить руководителя',
      del_error: 'Раздел содержит сервисы. Удалить можно только пустой раздел',
    },
    start_bp: 'Создать заявку',
    cant_start: 'Нельзя создать заявку, нужно минимум 2 шага',
    start_title: 'Создание новой заявки',
    bp_started: 'Заявка создана',
    bp_start_error: 'Не удалось создать заявку',
    del_error: 'Не удалось удалить группу',
    succ: 'Успешно завершена',
    rejected: 'Отклонена',
    review: 'Ознакомьтесь пожалуйста',
  },
  // Опросы
  polls: {
    title: 'Опросы',
    new: 'Например, опрос вовлеченности',
    empty: 'Доступных опросов пока нет, но вы можете создать опрос, чтобы оперативно собрать мнения ваших коллег',
    create: 'Добавить опрос',
    name: 'Добавьте название опроса',
    descr: 'Введите описание вопроса, которое увидят сотрудники',
    enter_descr: 'Например: Нам важно, ваше мнение. Помогите нам сделать компанию ещё лучше!',
    deleting: 'Удаление опроса',
    delete: 'Удалить опрос «{name}»?',
    deleted: 'Опрос удален',
    q_amount: 'Вопросов в опросе',
    q_short: 'воп.',
    you_can: 'Вы можете пройти опрос',
    enter_poll: 'Перейти в опрос',
    description: 'Описание',
    ph_name: 'Введите название опроса',
    ph_descr: 'Добавьте описание опроса',
    editing: 'Редактирование',
    types: {
      opened: 'Открытый',
      opened_descr: 'Сотрудники видят ответы друг друга',
      opened_full: 'Этот опрос открытый, все могут видеть ответы других участников',
      closed: 'Закрытый',
      closed_descr: 'Сотрудники видят только свои ответы, администраторы видят все данные',
      closed_full: 'Ответы опроса закрыты, вы можете видеть <b>только свои</b>. Администратор может видеть все ответы.',
      anonymous: 'Анонимный',
      anonymous_descr: 'Сотрудники видят только свои ответы, администратор видит обезличенные ответы',
      anonymous_full: 'Данный опрос является <b>анонимным</b>, никто не видит персональные ответы',
    },
    post_msg_created: 'Создан новый опрос',
    tbl: {
      main: {
        name: 'Название опроса',
        anonymous: 'Анонимный',
        author: 'Автор',
        created: 'Дата создания',
        type: 'Тип опроса',
        questions: 'Вопросов',
        answered: 'Ответов',
        actions: 'Действия'
      },
    },
    qs: 'Вопросы',
    q_n: 'Вопрос {n}',
    add_q: 'Добавить вопрос',
    mandatory_q: 'Обязательный вопрос',
    delete_conf: 'Вопрос будет удален, вы уверены?',
    text_q: 'Текст вопроса',
    text_ph: 'Например, знаете ли вы, чего от вас ожидает работодатель?',
    kind_q: 'Вид вопроса',
    answer: 'Ответ',
    answer_n: 'Ответ {n}',
    answers: 'Ответы',
    my_answer: 'Мой ответ',
    no_answer: 'Нет ответа',
    choose_range: 'Выберите значение от 1 до {range}',
    you_choose: 'Вы выбрали: {val}',
    no_variants: 'Нет вариантов',
    add_ans: 'Добавить вариант ответа',
    range: 'Диапазон оценки',
    loading_results: 'Загружаются результаты',
    nothing: 'ничего',
    r_1_5: 'от 1 до 5',
    r_1_10: 'от 1 до 10',
    r_1_100: 'от 1 до 100',
    score: 'Оценка',
    ans_types: {
      short: 'Краткий ответ текстом',
      long: 'Развернутый ответ текстом',
      range: 'Выбор числа в диапазоне',
      list1: 'Один вариант из списка',
      list_m: 'Несколько вариантов из списка',
    },
    export: 'Выгрузить в Excel',
    passed: 'Опрос прошли',
    finished: 'Сотрудник прошел опрос',
    end: 'Завершить',
    next: 'Следующий вопрос',
    back: 'Предыдущий вопрос',
    progress: 'Вы ответили на {num} вопросов из {count}',
    your_results: 'Ваши результаты',
    no_results: 'Результатов пока нет',
    results: 'Результаты',
    result_tabs: {
      main: 'Общие',
      table: 'Таблицей',
      not_passed: 'Не прошли опрос',
    },
    result_table: {
      name: 'ФИО',
      position: 'Должность',
      dept: 'Отдел',
      isChief: 'Руководитель',
      city: 'Город',
      gender: 'Пол',
      workingTime: 'Время работы, мес.',
    },
    filters: {
      gender: 'Пол',
      ages: 'Возраст',
      depts: 'Подразделение',
      cities: 'Город',
      workingTimes: 'Время работы',
      period: 'Период прохождения',
      chief: 'Руководитель',
      positions: 'Должность',
      active: 'Активные фильтры',
      execStart: 'Пройден после',
			execFin: 'Пройден до',
      ageOptions: {
        lt20: 'До 20 лет',
        lt30: '20-30 лет',
        lt40: '30-40 лет',
        lt50: '40-50 лет',
        gt50: '50+ лет'
      },
      chiefOptions: {
        isChief: 'Руководитель',
        isNotChief: 'Не руководитель',
      },
      timesOptions: {
        lt3m: 'Меньше 3 месяцев',
        lt1y: '3 месяца - 1 год',
        lt5y: '1-5 лет',
        lt10y: '5-10 лет',
        gt10y: '10+ лет',
      },
    },
    questions_num: 'Вопросов',
    passed_num: 'Прошли опрос',
    date: 'Дата создания',
    series: {
      amount: 'Количество',
    },
    result_hint: '{num} ответов или {percent}%',
    del_filter: 'Сбросить фильтр',
    avg: 'Средний результат',
    err_excel: 'Ошибка при получении Excel файла',
    err_name: 'Не указано название',
    have_passed: 'Вы уже проходили этот опрос',
    is_passed: 'Пройден',
    take: 'Пройти опрос',
    view_results: 'Просмотреть результаты',
    loading: 'Опрос загружается',
    grants_article_title: 'Доступы к статье',
    empl_label: 'Выбрать сотрудников для опроса',
    empl_select: 'Выберите сотрудников',
    grants_title: 'Доступы к опросу',
    for_all: 'Для всех сотрудников',
    closed: 'Закрытые результаты',
    anon_opt: 'Опрос анонимный',
    actions: 'Действия с опросом',
    no_data: 'Нет данных по опросу',
    saved_new: 'Новый опрос сохранен',
    saved: 'Опрос сохранен',
    thanks: 'Спасибо за прохождение опроса!',
    return: 'Вернуться к опросам',
    see_results: 'Результаты вы можете увидеть, если опрос не является закрытым',
    answers_title: 'Ответы сотрудников на вопрос',
    answered_users: 'Сотрудники, указавшие вариант ответа',
    who_answered: 'На вопрос <b>«{question}»</b> сотрудники ответили:',
    answer_choosen: 'Вариант ответа <b>«{question}»</b> выбрали:',
    errors: {
      access: 'У вас нет доступа к этому опросу',
      valid: 'Ошибки при проверке опроса',
      no_name: 'Не заполнено название опроса',
      no_more15ans: 'Нельзя добавить больше 15 ответов!',
      nothing: 'Не введено ни одного вопроса, сохранение невозможно',
      not_all: 'Не все вопросы заполнены',
      no_mandatory: 'Не указан хотя бы один обязательный вопрос',
      no_ans: 'У некоторых вопросов с вариантами ответов не указано ни одного ответа',
      ans_not_filled: 'У некоторых вопросов есть незаполненные ответы',
      excel: 'Ошибка при получение Excel файла',
      no_recipients: 'Не выбраны получатели закрытого опроса'
    },
  },
  // Чаты
  chat: {
    me: 'Я',
    no_rooms: 'Нет ни одной комнаты',
    no_room_code: 'Не задан код комнаты',
    create_q: 'Создать комнату?',
    no_msg: 'Пока нет ни одного сообщения',
    enter_name: 'Введите название чата',
    create_private: 'Создать личный чат',
    room_added: 'Комната добавлена',
    room_deleted: 'Комната удалена: {room}',
    del_room: 'Удалить чат',
    members: 'Участников: {len}',
    error_rooms: 'Ошибка при получении комнат',
    error_add_room: 'Ошибка при добавлении комнаты',
    error_del: 'Ошибка удаления',
    with: 'Чат с: ',
    add_empl: 'Добавить сотрудника',
    del_empl: 'Удалить сотрудника',
    delete_all: 'Удалить чат и все сообщения?',
    delete_room: 'Комната и все сообщения будут удалены',
    you_added: 'Вы добавлены в комнату чата',
    you_removed: 'Вы удалены из комнаты: {name}',
    private_started: 'С вами начал личный чат {with}',
    new_msg: 'Новое сообщение в чате {name}',
  },
  // Структура
  struct: {
    title: 'Структура компании',
    adm: 'Административная',
    org: 'Организационная',
    func: 'Функциональная',
    project: 'Проектная',
    main_org: 'Главное управление',
    main_func: 'Главное подразделение',
    main_prj: 'Главный проект',
    choose_type: 'Вид структуры компании',
    hide_types: 'Скрыть структуры',
    expand_all: 'Разворачивать полностью',
    t_diagram: 'В виде диаграммы',
    t_tree: 'В виде списка-дерева',
    select_dept: 'Выберите отдел для переноса',
    select_dept_add: 'Выберите отдел для добавления',
    no_empl: 'Нет сотрудников',
    add_empl: 'Добавить сотрудника',
    new_name: 'Укажите новое название',
    new_empl: 'Добавить сотрудников',
    sel_mng: 'Выбрать руководителя',
    del_empl: 'Удалить сотрудника',
    add_dept: 'Добавить отдел',
    move_dept: 'Переместить отдел',
    pin_dept: 'Закрепить отдел',
    unpin_dept: 'Открепить отдел',
    change_info: 'Изменить данные',
    no_descr: 'Нет описания отдела',
    replace_dept: 'Перенести отдел',
    del_dept: 'Удалить отдел',
    error_load: 'Не удалось загрузить данные о структуре',
    empl_added: 'Сотрудник добавлен',
    empl_deleted: 'Сотрудник удален',
    empl_not_deleted: 'Сотрудник не удален',
    empl_deleted_full: '{name} удален из отдела',
    empl_added_full: 'Сотрудник(и) добавлен(ы) в отдел',
    empl_not_added: 'Сотрудник не добавлен',
    del_empl_confirm: 'Подтвердите удаление сотрудника',
    del_empl_content: 'Сотрудник «{name}» будет удален',
    err_no_company: 'Не задана текущая компания',
    mngr_set: 'Руководитель установлен',
    no_manager: 'Руководитель не назначен',
    mngr_set_full: 'Руководитель отдела теперь {name}',
    err_manager: 'Ошибка выбора руководителя',
    saved: 'Структура сохранена',
    err_save: 'Ошибка при сохранении',
    err_not_found: 'Не найден отдел',
    err_no_empl: 'В данном отделе нет сотрудников',
    add_empl_to_dept: 'Добавить сотрудника в отдел',
    err_empl_in_dept: 'В отделе есть сотрудники ({len})',
    err_dept_delete: 'Ошибка удаления отдела',
    err_root_dept: 'Головной отдел нельзя удалить',
    dept_del_confirm: 'Подтвердите удаление отдела',
    dept_del_content: 'Отдел «{name}» будет удален',
    find_by_fullname: 'Поиск по ФИО',
    title_choose: 'Выберите новых сотрудников',
    title_del: 'Выберите сотрудника для удаления',
    title_mngr: 'Выберите начальника отдела нажатием на иконку в списке',
    title_new_dept: 'Создание нового подразделения',
    title_edit_dept: 'Изменение подразделения',
    upper_dept: 'Вышестоящее подразделение',
    please_name: 'Пожалуйста, введите название отдела',
    ph_name: 'Отдел маркетинга',
    ph_descr: 'Помогаем потенциальным клиентам узнать о нашем продукте и стать амбассадорами бренда',
    employees: 'Сотрудники',
    manager: 'Руководитель',
    managers: 'Руководители',
    employee: '{n} сотрудников | {n} сотрудник | {n} сотрудника | {n} сотрудников',
    employees_of: 'сотрудников',
    open_list: 'Раскрыть список',
    in_the_dept: 'в этом отделе',
    include_sub: 'включая все подчиненные',
    err: {
      move_same: 'Отдел совпадает с текущим',
      move_child: 'Нельзя переместить в дочерний отдел',
    },
  },
  // Не молчи
  quiet: {
    title: 'Не молчи',
    user_mode: 'Режим отправки',
    admin_mode: 'Режим редактирования',
    recipient: 'Назначьте сотрудников для рассмотрения обращений',
    list_header: 'Список сотрудников, получающих обращения',
    what: 'Что такое «Не молчи»',
    no_service: 'Сервис пока недоступен...',
    no_srv_descr: 'Здесь вы сможете сообщить о любом правонарушении или злоупотреблении сотрудников. Администратор скоро предоставит доступ  к сервису',
    recipients: 'Обращение рассмотрит',
    descr: 'Это конфиденциальный канал, в котором вы можете сообщить о любом правонарушении или злоупотреблении \n    сотрудника: взятке, откате, конфликте интересов, недостойном поведении',
    enter_name: 'Введите свое имя',
    label_name: 'Автор обращения',
    anon: 'Анонимно',
    problem_label: 'Опишите суть жалобы',
    problem: 'До меня дошли сведения, что...',
    name: 'Имя',
    empls: 'Сотрудники, получающие обращения',
    sent: 'Сообщение отправлено',
    sent_full: 'Ваше письмо отправлено. Его рассмотрят в ближайшее время. Благодарим Вас за бдетельность!',
    rules: {
      name: 'Представьтесь или сделайте обращение анонимным',
      trouble: 'Добавьте подробное описание вашего обращения',
    },
  },
  // Есть идея
  idea: {
    title: 'Предложить идею',
    recipient: 'Назначьте сотрудников для рассмотрения предложений',
    recipients: 'Идею рассмотрит',
    no_service: 'Сервис пока недоступен...',
    no_srv_descr: 'В этом сервисе вы сможете предлагать идеи для улучшения процессов в компании. Администратор скоро предоставит доступ к сервису',
    list_header: 'Список сотрудников, получающих предложения',
    descr: 'Здесь ваши сотрудники могут предложить идеи для улучшения процессов внутри компании. Назначьте сотрудников для рассмотрения предложений',
    fio: 'Введите имя и фамилию',
    empls: 'Сотрудники, получающие обращения',
    no_receivers: 'Выберите получателей обращений',
    fields: {
      name: {
        label: 'Автор идеи',
        hint: 'Фамилия и имя',
        error: 'Нужно указать свои данные',
      },
      describe: {
        label: 'Опишите текущий процесс',
        hint: 'Хочу поделиться своими мыслями по поводу...',
        error: 'Нужно указать какой процесс надо улучшить',
      },
      disadv: {
        label: 'Улучшение',
        hint: 'Я считаю, что можно улучшить...',
        error: 'Нужно указать недостаток',
      },
      changes: {
        label: 'Изменения',
        hint: 'Я предлагаю изменить...',
        error: 'Нужно описать, что нужно сделать',
      },
      results: {
        label: 'Какой результат могут принести изменения?',
        hint: 'Предлагаемые мною изменения приведут...',
        error: 'Опишите предполагаемый результат изменений',
      },
    },
    got: 'Готово!',
    got_full: 'Ваше предложение отправлено. Спасибо,<br/> что помогаете делать наше общее дело лучше!',
    sent: 'Предложение отправлено',
    sent_descr: 'Ваше предложение отправлено администратору на почту'
  },
  // Сотрудники
  empls: {
    title: 'Управление сотрудниками',
    admin: 'Администратор',
    empl: 'Сотрудник',
    mng_tab: 'Все сотрудники ({n})',
    rights_tab: 'Права и роли',
    stat_tab: 'Статистика настроения',
    other_tab: 'Прочие настройки',
    notif_tab: 'Уведомления',
    ui_tab: 'Интерфейс',
    company_info_tab: 'Данные компании',
    f: {
      name: 'Имя',
      mname: 'Отчество',
      lname: 'Фамилия',
      bd: 'Дата рождения',
      phone: 'Телефон',
      lang: 'Язык',
      int_phone: 'Внутренний телефон',
      email: 'Эл. почта',
      position: 'Должность',
      cabinet: 'Кабинет',
      department: 'Подразделение',
      workingSince: 'В компании с',
      pwd: 'Пароль',
      pwd2: 'Подтверждение пароля',
    },
    about: 'О себе',
    about2: 'Информация о себе',
    soc_nets: 'Социальные сети',
    for_hr: 'Кадровые документы',
    hr: 'Информация для HR',
    hr_files: 'Файлы для HR',
    hrm_data_title: 'Данные из ЗУП',
    show_del: 'Показывать удаленных',
    new: 'Новый сотрудник',
    greet_new: 'Приветствуем нового пользователя',
    written: 'Сотрудник записан',
    saved: 'Сотрудник {fio} сохранен',
    created: 'Сотрудник {fio} создан',
    total: 'Всего сотрудников',
    incl_del: '(включая %{len} удаленных)',
    sent: 'Писем отправлено за ',
    not_sent: 'Письма еще не отправлялись',
    pwd_reset: 'Сбросить пароль',
    reset_by_admin: 'Сброс пароля (администратором)',
    reset: 'Сбросить',
    change: 'Изменение данных сотрудника',
    enter_name: 'Введите имя сотрудника',
    choose_bd: 'Выберите дату',
    enter_pwd: 'Введите пароль',
    enter_mname: 'Введите отчество сотрудника',
    enter_lname: 'Введите фамилию сотрудника',
    enter_email: 'Введите e-mail',
    enter_position: 'Введите должность',
    enter_lang: 'Выберите язык',
    will_del: 'будет удален',
    fired: 'Уволен',
    fireds: 'Уволенные',
    confirm_del: 'Подтвердите удаление сотрудника',
    list_del : 'Удаление из списка сотрудников отделов',
    exclude: 'Исключение из состава участников групп',
    blocked: 'Вход на портал будет заблокирован',
    pwd_admin_change: 'У пользователя <b>{user}</b>  будет установлен новый пароль',
    enter_new_pwd: 'Введите новый пароль',
    repeat_pwd: 'Повторите пароль',
    export_sendsay: 'Обновить e-mail адреса',
    export_sendsay1: 'Выгрузить в Sendsay',
    export_process: 'Сотрудники выгружаются в SendSay...',
    export_finish: 'Выгрузка закончена..',
    export_err: 'Выгрузка завершилась с ошибкой: {err}',
    add_users: 'Заполните список сотрудников для добавления',
    get_excel: 'Скачать шаблон Excel',
    select_excel: 'Загрузить из Excel',
    has_email_repeats: 'Есть повторяющиеся e-mail адреса',
    user_exists: 'Пользователь c таким e-mail уже существует',
    success: 'Сотрудник успешно зарегистрирован',
    tbl: {
      main: {
        fio: 'ФИО',
        email: 'e-mail',
        positions: 'Должности',
        phone: 'Телефон',
        is_admin: 'Роль',
        actions: 'Действия'
      }
    },
    rights: {
      wall: 'Публикации на стене',
      groups: 'Использование групп',
      calendar: 'Использование календаря',
      profile_edit: 'Изменение профиля',
      knowl_base: 'Использование базы знаний',
      events_create: 'Создание мероприятий',
			task_tracker: 'Таск-трекер',
      desk_add: 'Добавление досок',
      vacancies: 'Использование вакансий',
      services: 'Использование сервисов',
      structures: 'Использование орг. структур',
      no_silent: 'Использование функции \'Не молчи\'',
      ideas: 'Использование функции \'Предложить идею\'',
      kb_files: 'Создавать материалы в базе знаний',
      vacations: 'Доступ к отпускам',
      vacations_edit: 'Управлять отпусками',
    },
    rights_change: 'Изменение прав',
    rights_add: 'Вам добавлены права ',
    rights_rem: 'У вас сняты права ',
    rights_chng: {
      wall: "на публикации на стене",
      groups: "на использование групп",
      profile_edit: 'на редактирование профиля',
      calendar: "на использование календаря",
      events_create: 'на добавление событий',
      task_tracker: 'на таск-трекер',
      desk_add: 'на добавление досок задач',
      knowl_base: "на использование базы знаний",
      kb_files: "на добавление материалов в базу знаний",
      vacancies: "на использование вакансий",
      services: "на использование сервисов",
      structures: "на использование орг. структур",
      no_silent: "на использование функции 'Не молчи'",
      ideas: "на использование функции 'Предложить идею'",
      polls: 'на использование опросов',
      admin: "администратора",
      chat: "на чаты",
      shop: 'на доступ к магазину товаров',
      adaptation: 'на доступ к адаптации',
      vacations: 'на доступ к отпускам',
      vacations_edit: 'на изменение отпусков',
    },
    funcs_on: 'Включена возможность: ',
    funcs_off: 'Выключена возможность: ',
    funcs: {
      wall: "Публикации на стене",
      groups: "Использование групп",
      profile_edit: 'Редактирование профиля',
      calendar: "Использование календаря",
      events_create: 'Создание событий',
      task_tracker: 'Таск-трекер',
      desk_add: 'Добавление досок задач',
      knowl_base: "Использование базы знаний",
      kb_files: "Добавление материалов в базе знаний",
      vacancies: "Использование вакансий",
      services: "Использование сервисов",
      structures: "Использование орг. структур",
      no_silent: "Использование функции 'Не молчи'",
      ideas: "Использование функции 'Предложить идею'",
      polls: 'Опросы',
      chat: "Чаты",
      shop: 'Магазин',
      adaptation: 'Адаптация',
      vacations: 'Использование отпусков',
      vacations_edit: 'Изменение отпусков',
    },
    err: {
      del: 'Ошибка при удалении сотрудника',
      del_admin: 'Невозможно удалить администратора',
      del_self: 'Невозможно удалить самого себя',
      save: 'Ошибка при сохранении сотрудника',
      email: 'Сотрудник с таким емейлом уже зарегистрирован',
      role_set: 'Ошибка назначения роли',
      role_del: 'Ошибка удаления роли',
      role_change: 'Ошибка изменения роли',
      own: 'Нельзя изменять собственную роль',
      data: 'Ошибка в данных',
      not_found: 'Сотрудник не найден',
      export: 'Ошибка экспорта в SendSay',
    },
    rules: {
      name: 'Необходимо указать имя',
      last_name: 'Необходимо указать фамилию',
      symbols: 'Недопустимые символы в строке',
      email: 'Необходимо указать e-mail',
      corr_email: 'Укажите корректный e-mail',
      pwd: 'Укажите пароль',
      pwd_len: 'Пароль должен быть не менее 6 символов',
      pwd_confirm: 'Необходимо подтвердить пароль',
      pwd2: 'Пароли не совпадают',
      max20: 'Максимум 20 символов',
    },
    restore_del: 'Восстановить удаленного сотрудника',
    empl_will_restore: 'Сотрудник «{name}» будет восстановлен на портале',
    role_change: 'Изменение роли',
    role_changed: 'Роль изменена',
    role_add_to_all: 'Добавить роль всем сотрудникам?',
    role_rem_from_all: 'Снять роль со всех сотрудников?',
    role_succ: 'Роль всем присвоена успешно',
    role_deleted: 'Роль удалена у всех успешно',
    remove_adm: 'Снять с {fio} права администратора',
    make_adm: 'Сделать сотрудника {fio} администратором?',
    deleted: 'удален',
    deleting: 'удаление',
    restored: 'восстановлен',
    restoring: 'восстановление',
    replaced: 'Замена в группах',
    del_as_admin: 'Удаление как начальника',
    empls_was: 'Сотрудник {fio} был {del_type}',
    mood_period: 'Период анализа',
    two_weeks: 'Две недели',
    one_week: 'Неделя',
    no_features: 'У клиента нет доступных функций, для которых назначаются роли',
    features: {
      wall: "Стена",
      address: "Адресная книга",
      groups: "Группы",
      calendar: "Календарь",
      profile_edit: 'Редактирование профиля',
      knowl_base: "База знаний",
      events_create: 'Создание событий',
      task_tracker: 'Таск-трекер',
      desk_add: 'Добавление досок задач',
      vacancies: "Вакансии",
      services: "Сервисы",
      structures: "Орг. структуры",
      no_silent: "«Не молчи»",
      polls: "Опросы",
      ideas: "«Предложить идею»",
      kb_files: "Редактор базы знаний",
      chat: "Чаты",
      shop: "Магазин",
      adaptation: 'Адаптация',
      vacations: 'Отпуски',
      vacations_edit: 'Изменение отпусков',
      dark_theme: 'Тёмная тема',
    },
    forbid: 'Запретить',
    allow: 'Разрешить',
    confirmation: 'Подтверждение',
    role_was_added: 'Роль добавлена успешно',
    role_was_removed: 'Роль снята успешно',
    role_was_all_add: 'Роль присвоена всем успешно',
    role_was_all_rem: 'Роль снята у всех успешно',
    notif_settings: 'Каналы оповещений компании',
    notif_empl_settings: 'Активность каналов оповещения сотрудника',
    tmpls: {
      code: 'Код шаблона',
      name: 'Название',
      title: 'Заголовок',
      body: 'Текст краткого шаблона',
      full: 'Текст полного шаблона',
      hint: 'Переменные',
    }
  },
  // Настройки компании
  admin: {
    company_settings: 'Настройки компании',
    logo_link: 'Ссылка на логотипе компании',
    start_page: 'Стартовая страница при входе',
    hrm_link: 'Адрес базы ЗУП',
    load_empls: 'Загрузить сотрудников',
    empls_replace: 'Обновить данные в профиле',
    empls_replace_warn: 'Внимание! Данные будут перезаписаны данными из 1С',
    load_struct: 'Загрузить структуру компании',
    load_hrm_ok: 'Загрузка завершена успешно',
    load_hrm_info: 'Загружено: {ok}, создано {cr_len} новых{created}',
    load_hrm_noemails: '. В ЗУП найдено {no_emails} сотрудников без e-mail, заполните и повторите загрузку',
    load_struct_hrm_ok: 'Структура компании загружена',
    other: 'Прочие настройки',
    info: {
      inn: 'ИНН',
      inn_ph: 'Введите 10 цифр',
      account: 'Расчетный счет',
      address: 'Юридический адрес',
      phone: 'Телефон компании'
    },
    errors: {
      inn: 'Некорректный формат ИНН',
      no_inn: 'Для интеграции с 1С необходимо заполнить ИНН в данных о компании',
      phone: 'Некорректный номер телефона',
    },
    ui: {
      show_surnames: 'Показывать отчество в адресной книге, структуре компании и профиле сотрудника',
      show_client_title: 'Показывать название организации в шапке портала',
      article_files_list: 'Показывать файлы в статьях как список',
      exportUserHRMData: 'Выгружать пользователей с данными из ЗУП',
      editor_type: 'Использовать легкий редактор',
      show_mood: 'Скрыть по умолчанию опрос настроения',
      links: {
        head: 'Полезные ссылки',
        title: 'Видимость блоков со ссылками',
        edit_title: 'Редактирование ссылки',
        name_ph: 'Опишите ссылку',
        link_ph: 'Введите ссылку',
        article: 'Статья',
        select_article: 'Выберите статью',
        ext: 'Внешняя ссылка',
        isBase: 'База знаний',
        saved: 'Блок ссылок сохранен',
        no_links: 'Нет ни одной ссылки',
        del_confirm: 'Точно удалить ссылку?',
        err: {
          title: 'Не указан заголовок',
          link: 'Не указана ссылка',
          link_url: 'Некорректная ссылка',
        },
      },
      icons: {
        title: 'Набор иконок приложения',
        copy: 'Кликните для копирования кода компонента',
      },
      colors: 'Все цвета'
    },
  },
  // Клиенты
  clients: {
    title: 'Управление клиентами',
    new: 'Новый клиент',
    total: 'Всего клиентов',
    hide: 'Скрыть заблокированных',
    active: 'Активен',
    block: 'Блокировка',
    clone: 'Клонирование клиента',
    del_client: 'Удалить клиента',
    tbl: {
      main: {
        name: 'Название, код',
        phone: 'Телефон',
        address: 'Адрес компании',
        users: 'Кол-во сотрудников',
        access_status: 'Статус',
        enter: 'Вход',
      },
    },
    err: {
      no_ws: 'Не указано имя воркспейса',
      no_name: 'Не указано новое название',
      import_err: 'Не удалось импортировать данные клиента',
    },
    import: 'Импорт из бэкапа',
    imported: 'Клиент импортирован успешно',
    new_ws_name: 'Новое имя воркспейса',
    new_name: 'Новое название клиента',
    full_deleting: 'Полное удаление клиента',
    del_disclaimer: 'Клиент будет удален вместе со своими данными (посты, статьи и т.д.). \nОтменить данную операцию невозможно. \nПодтвердите данное действие, поставив галочку в поле согласия',
    del_confirm: 'Подтвердить полное удаление данных клиента',
    sendsay_export: 'Выгрузка в SendSay',
    export_ok: 'Выгрузка в SendSay завершена',
    export_err: 'Выгрузка в SendSay не удалась: {err}',
    tabs: {
      data: 'Данные',
      design: 'Дизайн',
      features: 'Функционал',
      widgets: 'Виджеты на стене',
      other: 'Прочие доступы',
      services: 'Обслуживание',
    },
    name: 'Полное название',
    name_ph: 'Введите полное название',
    code: 'Код (workspace) клиента',
    code_ph: 'Введите краткий код',
    access: 'Доступ к системе',
    city: 'Город',
    city_ph: 'Введите город',
    phone: 'Телефон',
    address: 'Адрес',
    address_ph: 'Введите адрес',
    director: 'Директор',
    director_ph: 'Введите ФИО директора',
    tg: 'Канал Telegram',
    tg_ph: 'Укажите имя канала',
    space: 'Размер пространства',
    gb: 'ГБ',
    comments_label: 'Комментарий/заметки',
    comments_ph: 'Введите комментарий',
    comments: 'Заметки',
    logo: 'Логотип компании',
    header: 'Шапка страницы (Высота 160 пикс, ширина от 1000 пикс.)',
    sidepanel: 'Боковая панель',
    backcolor: 'Цвет фона',
    textcolor: 'Цвет текста',
    font: 'Шрифт',
    create_error: 'Ошибка создания клиента',
    save_error: 'Ошибка сохранения клиента',
    saved: 'Клиент сохранен',
    menu_customizing_title: 'Названия и порядок главного меню',
    order_saved: 'Порядок меню записан',
    new_item_name: 'Новое имя',
    export_data: 'Выгрузка всех данных',
    export: 'Выгрузка',
    maintenance: {
      heads: {
        convert: 'Преобразование руководителей в списочный вариант',
        btn: 'Запустить',
        success: 'Преобразование завершено',
      }
    }
  },
  // Страница и диалог уведомлений
	notifs: {
    title: 'Уведомления',
    tab: {
      all: 'Все',
      blogs: 'Блоги',
      cal: 'Календарь',
      groups: 'Группы',
    },
    headers: {
      new_blog_post: 'Новый пост в блоге',
      new_group_post: 'Новый пост в группе',
      feed: 'В ленте',
      events: 'События',
      new_user: 'Новый сотрудник',
      bd: 'День рождения',
      new_group_member: 'Новый участник в группе',
      event_del: 'Событие удалено',
      event_replaced: 'Событие перенесено',
    },
    errors: {
      no_tmpl_code: 'Не указан код шаблона',
    },
    channels: {
      system: 'Звук и попап',
      email: 'Эл. почта',
      notif_center: 'Центр уведомлений',
      tg: 'Телеграм',
      msteams: 'MS Teams',
      slack: 'Slack',
    },
    not_set: 'Не заданы. Установите в настройках уведомлений компании',
    no_notifs: 'Нет настроенных шаблонов уведомлений',
    tabs: {
      notifs: 'Настройки уведомлений',
      tempates: 'Шаблоны уведомлений',
    },
    channel_settings: 'Настройки каналов уведомлений',
    trigger: 'Уведомление',
    check_read: 'Отметить все как прочитанные',
    today: 'Сегодня',
    yesterday: 'Вчера',
    this_week: 'На этой неделе',
    earlier: 'Ранее',
    hide: 'Скрыть прочитанное',
    load_more: 'Загрузить более ранние уведомления',
    nothing: 'Пока нет уведомлений',
    delete: 'Шаблон будет полностью удален',
    deleted: 'Шаблон успешно удален',
    only_for_empls: 'Уведомления доступны только для аккаунтов сотрудников',
    tmpl_groups: {
      posts: 'Посты (публикации)',
      groups: 'Группы',
      events: 'События и мероприятия',
      services: 'Услуги и сервисы',
      polls: 'Опросы и голосования',
      shop: 'Магазин',
      resp: 'Обращения и отклики',
      other: 'Прочие события',
      tasks: 'Таск-трекер',
      adapt: 'Адаптация',
    }
	},
  // 
  shop: {
    title: 'Магазин',
    add_reward: 'Добавить награду',
    accrue: 'Начисление',
    nullify: {
      button: 'Обнулить баланс всем',
      alert: 'Вы уверены, что хотите обнулить все балансы?',
      confirm: 'Обнулить',
    },
    tabs: {
      shop: 'Магазин',
      stat: 'Статистика',
      achievements: 'Достижения',
      settings: 'Настройки',
      balances: 'Баланс',
    },
    sort: {
      cost_down: 'По убыванию цены',
      cost_up: 'По возрастанию цены',
      date: 'По дате добавления',
      no: 'Без сортировки',
    },
    errors: {
      ach_saving: 'Ошибка сохранения достижения',
      item_del: 'Ошибка удаления товара',
      item_was_sent: 'Товар нельзя удалить, т.к. имеются продажи',
      coins_not_canceled: 'Начисление или награда не были аннулированы'
    },
    no_settings: 'Не заданы настройки магазина',
    buy_btn: 'Приобрести',
    name: 'Название магазина',
    curr_name: 'Название валюты',
    curr_short: 'Валюта сокращенно',
    managers: 'Ответственныe',
    description: 'Описание магазина и валюты',
    curr_icon: 'Иконка валюты',
    ach_name: 'Название достижения',
    sett: {
      name: 'Название магазина',
      name_ph: 'Введите название магазина для вывода в заголовке',
      coin: 'Название валюты',
      coin_ph: 'Введите полное название валюты',
      code: 'Код',
      code_ph: 'УЕ',
      descr: 'Описание магазина и валюты',
      descr_ph: 'Введите описание товаров, которые доступны сотрудникам и другую полезную информацию'
    },
    stat_table_accr: {
      empl: 'Сотрудник',
      achievements: 'Награда',
      coins: 'Монеты',
    },
    stat_table_purch: {
      empl: 'Сотрудник',
      item: 'Покупка',
      cost: 'Стоимость',
    },
    stat_table_all: {
      num: "№",
      time: 'Дата операции',
      empl: 'Сотрудник',
      achievement: 'Награда',
      item: 'Покупка',
      comment: 'Комментарий',
      amount: 'Сумма',
    },
    balance_tbl: {
      user: 'Сотрудник',
      balance: 'Баланс',
      dept: 'Отдел',
      position: 'Должность',
      achievements: 'Достижения',
    },
    saved: 'Данные магазина сохранены',
    ach: {
      header: 'Достижение',
      name: 'Название достижения',
      new_ph: 'Лучшему Бадди',
      pic: 'Добавить изображение',
      max: 'Достигнуто максимальное количество наград',
      no: 'Достижения еще не добавлены',
      no_ach: 'Без награды',
      add: 'Добавьте достижение',
      change: 'Изменение достижения',
      edit: 'Изменить достижение',
      delete: 'Удалить достижение',
      del_confirm: 'Удалить достижение из базы?',
      deleted: 'Достижение удалено',
      err_fill: 'Не заполнены обязательные поля',
      name_ph: 'Введите имя награды',
      descr_ph: 'Его подопечный с блеском прошел испытательный срок',
    },
    auto_ach: {
      greetingPost: 'За благодарность',
      poll: 'За опрос',
      profileCompletion: 'За заполнение профиля',
      birthDay: 'За день рождения',
      suggestIdea: 'За хорошую идею',
      anniversary: 'За годовщину',
      title: 'Настройки автоначисления достижений и монет',
      empty: 'Выберите награду или монеты',
    },
    periodic_ach: {
      workingPeriod: 'За время работы',
      years: 'Годы',
    },
    items: {
      header: 'Товар',
      no: 'Пока нет доступных товаров в магазине',
      add: 'Добавить товар',
      new: 'Худи',
      edit: 'Изменить товар',
      del: 'Удалить товар',
      del_confirm: 'Удалить товарную позицию?',
      deleted: 'Товар в магазине удален',
      title: 'Товар в магазине',
      name: 'Название позиции',
      descr: 'Описание',
      cost: 'Стоимость',
      start_amount: 'Начальный остаток',
      amount: 'Остаток',
      now_amount: 'Текущий остаток',
      adding: 'Добавление товара',
      add_amount: 'Добавить количество',
      available: 'Доступен',
      pics: 'Добавить изображение',
      size_tip: 'Рекомендуемый размер 200х360 пикс',
      err_fill: 'Не заполнены все обязательные поля товарной позиции',
      err_cost: 'Ошибка в значении цены',
      err_amount: 'Ошибка в значении остатка',
      not_available: 'Не доступен',
    },
    coins: {
      name: 'Койны',
      accrue: 'Начисление {name}',
      who: 'Кому',
      who_many: 'Расширенный отбор сотрудников',
      achievement: 'Достижение',
      descr: 'Комментарий',
      public: 'Опубликовать на стене',
      public2: 'На стене появится пост с упоминанием сотрудника',
      ph_who: 'Выберите кому начислить монеты',
      ph_achieve: 'Выберите достижение',
      cancel: 'Начисление будет аннулировано',
      canceled: 'Начисление успешно аннулировано',
      err: {
        no_data: 'Не выбрано ни количество ни награда',
        amount: 'Не заполнено или неверное значение количества',
        too_much: 'Слишком большое или маленькое количество валюты',
        user: 'Не заполнен получатель монет',
        achieve: 'Не заполнено достижение',
        accrue: 'Ошибка при начислении монет',
        cancel: 'Ошибка отмены начисления',
      },
      success: 'Начисление успешно выполнено',
      added: '{user} получил {amount} монет',
    },
    buy: {
      title: 'Покупка товара',
      you_buy: 'Вы покупаете',
      you_have: 'У вас накоплено',
      confirm: 'Купить',
      ok: 'Покупка совершена',
      bought: 'Вы приобрели {name}, поздравляем!',
      error: 'Ошибка при покупке',
      sa_cannot: 'Суперадмин не может покупать товары клиента',
      not_enough: 'Не хватает монет для покупки',
    },
    stats: {
      period: 'За период',
      dept: 'Отдел',
      tab_accr: 'Начисления',
      tab_purch: 'Покупки',
      all: 'Все',
      add: 'Добавления',
      buy: 'Покупки',
    },
    post: {
      earn: 'заработал',
      ach: 'достижение ',
      and_coins: 'и {coins} {coin_name}',
    },
  },
  // Таск трекер
  task_tracker: {
    desks: {
      title: 'Доски задач',
      no: 'Нет досок',
      add_title: 'Добавить доску',
      add_lane: 'Добавить еще колонку',
      add_task: 'Добавить карточку',
      sort_date: 'Сортировать по дате',
      change_bg: 'Сменить фон',
      info_title: 'О доске',
      fav: 'Избранное',
      fav_r: 'Избранные доски',
      my: 'Мои доски',
      own: 'Я автор',
      other: 'Прочие',
      oftens: 'Часто посещаемые',
      in_groups: 'Доски в группах',
      owner: 'Владелец',
      admins: 'Администраторы',
      kanban: 'Канбан',
      table: 'Таблица',
      name: 'Название доски',
      filter: 'Фильтр',
      description: 'Описание',
      active: 'Активность',
      status: 'Статус доски',
      delete_lane: 'Удалить колонку и все задачи',
      confirm_del_lane: 'Точно удалить колонку и задачи в ней?',
      new_lane: 'Новая панель',
      deleted_lane: 'Панель удалена',
      desk_saved: 'Информация о доске сохранена',
      copy_url: 'Скопировать ссылку на доску',
      copied_url: 'Ссылка на доску скопирована',
      copy: 'Скопировать доску',
      show_ended: 'Показать завершенные',
      show_ended_tasks: 'Показать завершенные карточки',
      hide_ended: 'Скрыть завершенные',
      show_all_tasks: 'Показать все карточки',
      finish_confirm: 'Завершить доску и все задачи в ней?',
      finish: 'Завершить доску',
      finished: 'Завершена',
      restore: 'Восстановить',
      deleting: 'Удаление карточек',
      all_finish: 'Все задачи тоже будут завершены',
      types: {
        ph: 'Тип задачи',
        open: 'Открытая',
        active: 'Активная',
        closed: 'Закрытая',
        hidden: 'Скрытая',
      },
      table_cols: {
        name: 'Название',
        lane: 'Статус',
        performer: 'Исполнитель',
        author: 'Постановщик',
        plan: 'Срок',
      },
      tasks_all: 'Всего карточек: {n}',
      tasks_finished: 'Завершенных: {n}',
      members_can: "Участники доски могут:",
      can_view: 'Просматривать карточки',
      can_comment: 'Комментировать карточки',
    },
    tasks: {
      lane_new: 'Новая',
      new: 'Новая задача',
      open: 'Открыть задачу',
      created: 'Задача успешно создана',
      saved: 'Задача сохранена',
      no_end_date: 'Бессрочная',
      copied_url: 'Ссылка на задачу скопирована',
      copied_name: 'Название задачи скопировано',
      checklist_new_item: 'Еще один пункт',
      end_of_week: 'До конца недели',
      end_of_month: 'До конца месяца',
      no_period: 'Плановый срок не задан',
      by_days: 'В днях',
      by_period: 'Период',
      uploaded: 'Файл загружен',
      no_users_selected: 'Не выбраны сотрудники',
      on_desk: 'В доске',
      priority: 'Приоритет',
      i_am: 'Я сам',
      sel_lane: 'Выберите новую панель',
      conf_move: 'Подтвердить перенос',
      will_move: 'Задача будет перенесена в секцию {to}. Продолжить?',
      task_moved: 'Задача перенесена из «{lane_from}» «{lane_to}»',
      task_moved_ok: 'Задача перенесена',
      check_list: 'Чек-лист',
      ch_done: 'Выполнено: {n} из {count}',
      tags: 'Метки',
      sel_color: 'Выберите цвет карточки',
      color: 'Цвет карточки',
      sel_border: 'Выберите цвет обводки',
      border: 'Обводка карточки',
      attachment: 'Вложение',
      time_title: 'Сроки исполнения',
      time: 'Сроки',
      hours: 'Учет часов',
      plan_hours: 'План',
      real_hours: 'Факт',
      hours_hint: 'Затраты:<br/>{real} из {plan} ч.',
      performer: 'Исполнитель',
      no_perf: 'Нет исполнителя',
      actions: 'Действия',
      copy: 'Копировать задачу',
      finish: 'Завершить задачу',
      finished: 'Задача успешно закрыта',
      is_finished: 'Задача завершена',
      del: 'Удалить задачу',
      owner: 'Постановщик',
      responsibles: 'Ответственные',
      coperformer: 'Соисполнитель',
      coperformers: 'Соисполнители',
      observer: 'Наблюдатель',
      observers: 'Наблюдатели',
      created_at: 'Дата создания: {d}',
      upd_at: 'Обновлена: {d}',
      del_confirm: 'Удалить задачу полностью?',
      deleted: 'Задача успешно удалена',
      confirm_del_file: 'Удалить вложение?',
      confirm_priority: 'Изменить приоритет?',
      del_checklist: 'Удалить чек-лист',
      for_adaptation: 'В адаптации',
      to_new_step: 'Вы перешли на следующий шаг',
      user_added: 'Сотрудник добавлен в задачу',
      user_removed: 'Сотрудник удален из задачи',
    },
  },
  // Адаптация
  adapt: {
    title: 'Адаптация',
    tracks: {
      add: 'Добавить трек',
      copy: 'Скопировать трек',
      delete: 'Удалить трек',
      del_confirm: 'При удалении будут удалены все данные о прошедших адаптациях',
      cant_delete: 'Невозможно удалить трек, есть незаконченные адаптации сотрудников',
      empty_title: 'Давайте добавим первый адаптационный трек',
      empty: '',
      tabs: {
        all: 'Все треки',
        users: 'Участники',
        analytics: 'Аналитика',
      },
      add_user: 'Добавить участника',
      new_track_name_ph: 'Адаптационный трек для IT новичка',
      run: 'Запустить',
      stop: 'Остановить',
      not_active: 'Трек не запущен',
      end: 'Трек завершен',
      errors: 'Трек содержит ошибки',
      manager: 'Менеджер',
      hr: 'HR-специалист',
      manager_ph: 'Выберите ответственного за модуль',
      settings: {
        dialog_title: 'Настройки модуля адаптации (онбординга)',
        mentors: 'Менторы в компании',
        hrs: 'HR-специалисты',
        achievements: 'Награды',
        ach_title: 'Награды, выдаваемые за срок работы в компании',
        no_ach: 'Нет наград за срок работы',
        polls: 'Опросы',
        polls_title: 'Опросы для прохождения после достижения срока работы в компании',
        days: 'Дни работы',
        coins: 'Монеты',
        incorrect_ach: 'Некорректные настройки - повторяются награды или число дней',
        incorrect_polls: 'Некорректные настройки - повторяются опросы или число дней',
        no_polls: 'Нет опросов, привязанных к сроку работы',
      },
      go_to_usertrack: 'Перейти к прогрессу сотрудника',
      no_user_track: 'Нет активной адаптации для данной роли',
    },
    step: {
      step_ph: 'Введите название шага',
      create_task: 'Создайте задачу для данного шага',
      now: 'Сразу же',
      set: {
        task: 'Задача',
        achiev: 'Награда',
        article: 'Статья или ссылка',
        poll: 'Опрос',
      },
      confirm_del_step: 'Удалить шаг?',
      confirm_del: {
        article: 'Подтвердите удаление статьи или ссылки',
        poll: 'Подтвердите удаление опроса',
        achievement: 'Подтвердите удаление награды и достижения',
        task: 'Подтвердите удаление задачи',
      },
      task: 'Задача',
      daysBeforeRun: 'Задержка запуска',
      achievement: 'Награда',
      resource: 'Статья или ссылка',
      poll: 'Опрос',
      task_todo: 'Задача для выполнения шага',
      resume: 'Возобновить',
      back: 'Откатить на шаг назад',
      task_expired: 'Срок выполнения истек ({days})',
      finished_time: 'Выполнена в: {time}',
      notifEnabled: 'Оповещение о начале шага'
    },
    track: {
      tabs: {
        info: 'Основное',
        scenario: 'Сценарий',
        users: 'Участники',
        analytics: 'Аналитика',
        extra: 'Дополнительно',
      },
      members_tbl: {
        name: 'ФИО',
        begin: 'Дата начала',
        end: 'Дата окончания',
        mentor: 'Наставник',
        chief: 'Руководитель',
        progress: 'Прогресс',
        actions: 'Действия',
        not_started: 'Не начат',
        not_ended: 'Не закончен',
        fired: 'Уволен',
        passed: 'Прошел испытательный срок',
        delete: 'Удалить адаптацию'
      },
      err: {
        no_name: 'Не заполнено название трека',
        no_new_user: 'Не указан сотрудник или ментор',
        user_on_track: 'Сотрудник уже добавлен в данный трек',
        step_not_saved: 'Ошибка при сохранении шага',
        required: 'Не заполнены обязательные поля',
        users_equal: 'Ментор или HR не могут совпадать с сотрудником на адаптации',
        del: 'Ошибка при удалении',
        cant_del_step: 'Невозможно удалить шаг, если адаптация уже в процессе'
      },
      empls_title: 'Сотрудники, проходящие адаптацию',
      no_onboarding: 'Вы в данный момент не проходите онбординг в нашей компании',
      back: 'Назад к вашим адаптациям',
      admin: 'Администратор',
      mentor: 'Наставник',
      chief: 'Руководитель',
      mentors: 'Наставники',
      hr: 'HR-менеджер',
      hrs: 'HR-менеджеры',
      novice: 'Новичок',
      users: 'Участники',
      time: 'Срок действия трека',
      who: 'Кому',
      task: 'Задача',
      no_stages: 'Нет ни одного этапа',
      add_stage: 'Добавить этап',
      add_step: 'Добавить шаг',
      add_user: 'Добавить сотрудника',
      stage_ph: 'Введите название этапа сценария',
      stage_added: 'Этап добавлен',
      no_steps: 'Нет шагов на данном этапе',
      step_added: 'Шаг добавлен успешно',
      step_saved: 'Шаг сохранен успешно',
      add: 'Добавить новый адаптационный трек',
      new_step: 'Новый шаг',
      progress: 'Прогресс',
      tasks: 'Задачи',
      starting_label: 'Создавать шаги сценария, считая от:',
      time_from_start: 'Начала адаптации',
      time_from_task: 'Пред. шага',
      task_title: 'Задача для выполнения',
      poll_title: 'Опрос',
      resource_title: 'Ресурс для ознакомления',
      ach: 'Вознаграждение',
      article_link: 'Статья или внешний ресурс',
      poll: 'Проверочный опрос',
      select_ach: 'Выберите награду за завершение этапа',
      select_kb: 'Выбрать из базы знаний',
      select_coins: 'Укажите число монет',
      created: 'Новый трек успешно создан',
      new_user_ph: 'Выберите нового участника трека',
      user_added: 'Новичок успешно добавлен в трек',
      no_mentor: 'Нет ментора',
      no_chief: 'Нет руководителя',
      no_empls: 'Нет активных адаптаций, где вы бы участовали',
      no_empls_for_mentor: 'Нет сотрудников, у которых вы ментор',
      no_empls_for_chief: 'Нет сотрудников из вашего отдела',
      confirm_del_stage: 'Удалить этап?',
      times: 'Сроки плана адаптации',
      no_time: 'Бессрочный',
      finished_time: 'Завершен в',
      end: 'Завершение трека',
      you_are: 'Ваша роль: ',
      chief_of: 'Вы руководитель отдела "{dept}"',
      changed: 'Адаптация изменена',
      changed_chief: 'Руководитель изменен в адаптации',
      changed_mentor: 'Ментор изменен в адаптации',
      changed_hr: 'HR изменен в адаптации',
    },
  },
  vacations: {
    title: 'Отпуск',
    title_sched: 'График отпусков',
    block: 'с {start} по {end}',
    add_vac: 'Добавить отпуск',
    days_left: 'У вас осталось {days} отпуска',
    too_much: '. Число дней превышает ваш остаток',
    delete_confirm: 'Удалить отпуск из графика?',
    deleted: 'Отпуск удален',
    past_date: 'Укажите дату позже текущей',
    date_cross_vac: 'Ошибка, отпуск пересекается с другим отпуском',
    days_not_enough: 'Не хватает остатка отпуска',
    past_period_alert: 'Отпуск будет создан в прошедшем периоде'
  },
}
