import * as Mutations from '@/store/user/mutations.type';
import * as Actions from '@/store/user/actions.type';
import * as AuthService from '@/services/auth/auth.service';
import * as Service from './user.service';

interface State {
  user: any | null;
  currentUser: any | null;
  users: any[];
  events: any[] | null;
  isLoggedIn: any | null;
  errorRegister: any | null;
  errorLogin: any | null;
  successMessage: any | null;
  userData: any | null;
  forgotInfo: any | null;
  resetInfo: any | null;
  activeSection: string;
  needReloadAvatar: string | null;
  exportProcessing: boolean;
  vacations: any[] | null,
}

const store: State = {
  user: null,
  currentUser: null,
  users: [],
  events: [],
  isLoggedIn: null,
  errorRegister: null,
  errorLogin: null,
  successMessage: null,
  userData: null,
  forgotInfo: null,
  resetInfo: null,
  activeSection: "",
  needReloadAvatar: null, // код юзера для точечного обновления аватарки, т.к. адрес не меняется при загрузке
  exportProcessing: false, // флаг, показывающий что идет процесс выгрузки
  vacations: [], // график отпусков
};

const getters = {
  workspace(state: State, root: any) {
    return root.userIsSuperAdmin
      ? (root?.currentClient?.workspace) || ''
      : state.userData?.client_id
  },
  user(state: State) {
    if (!state?.userData) return null
    const isSA = state.userData.roles.includes('superadmin')
    return isSA ? state.userData : state.users.find(u => u._id == state.userData._id);
  },
  forgotInfo(state: State) {
    return state.forgotInfo;
  },
  resetInfo(state: State) {
    return state.resetInfo;
  },
  currentUser(state: State) {
    return state.currentUser;
  },
  events(state: State) {
    return state.events;
  },
  users(state: State) {
    return state.users;
  },
  isLoggedIn(state: State) {
    return state.isLoggedIn;
  },
  errorRegister(state: State) {
    return state.errorRegister;
  },
  errorLogin(state: State) {
    return state.errorLogin;
  },
  successMessage(state: State) {
    return state.successMessage;
  },
  userData(state: State) {
    return state.userData;
  },
  uid(state: State) {
    return state.userData?._id || '';
  },
  userIsSuperAdmin(state: State) {
    if (!state?.userData) return false
    return state.userData.roles.includes('superadmin')
  },
  userIsAdmin(state: State) {
    if (!state?.userData) return false
    const roles = state.userData.roles
    return roles?.includes('admin') || roles?.includes('superadmin')
  },
  userHasRole(state: State, role: string) {
    if (!state?.userData) return false
    return state.userData.roles.includes(role)
  },
  activeSection(state: State): string {
    return state.activeSection
  },
};

const mutations = {
  [Mutations.SET_USER](state: State, user: any) {
    state.user = user;
  },
  [Mutations.FORGOT_INFO](state: State, forgotInfo: any) {
    state.forgotInfo = forgotInfo;
  },
  [Mutations.RESET_INFO](state: State, resetInfo: any) {
    state.resetInfo = resetInfo;
  },

  [Mutations.UPDATE_USER](state: State, user: any) {
    if (!state.user || user._id === state.user._id) {
      state.user = user;
    }
    if (state.users) {
      const userIndex = state.users!.findIndex(({ _id }) => _id === user._id);
      state.users.splice(userIndex, 1, user)
    }
  },
  [Mutations.SET_EVENTS](state: State, events: any[]) {
    state.events = events;
  },
  [Mutations.ADD_EVENT](state: State, event: any[]) {
    state.events = [...state.events!, event];
  },
  [Mutations.ADD_EVENTS](state: State, events: any[]) {
    state.events = [...state.events!, ...events];
  },
  [Mutations.CHANGE_EVENT](state: State, event: any) {
    if (state.events) {
      const index = state.events!.findIndex(({ _id }) => _id === event._id);
      state.events.splice(index, 1, event)
    }
  },
  [Mutations.CHANGE_EVENTS](state: State, data: any) {
    if (state.events) {
      // если пришли только измененные события - то как раньше
      if (data.changed.length) {
        data.changed.forEach((ev:any) => {
          const i = state.events?.findIndex(({_id}) => ev._id == _id)
          if (i) state.events?.splice(i, 1, {...ev})
        })
      } else {
        // удаленные удаляем
        state.events = state.events.filter(event => !data.deleted.includes(event._id))
        // новые добавляем
        state.events = [...state.events!, ...data.new];
      }
    }
  },
  [Mutations.REMOVE_EVENT](state: State, eventId: any) {
    if (state.events) {
      const index = state.events!.findIndex(({ _id }) => _id === eventId);
      state.events.splice(index, 1)
    }
  },
  [Mutations.REMOVE_EVENTS](state: State, eventIds: string[]) {
    if (state.events) {
      state.events = state.events.filter(event => !eventIds.includes(event._id))
    }
  },
  [Mutations.SET_USERS](state: State, users: any[]) {
    state.users = users;
  },
  [Mutations.SET_CURRENT_USER](state: State, user: any) {
    console.log('! STORE MUT. set curr user:', user._id, user.firstName + ' ' + user.lastName);
    state.currentUser = user;
  },
  [Mutations.INSERT_USERS](state: State, users: any[]) {
    state.users = [...state.users, ...users]
  },
  [Mutations.IS_LOGGED_IN](state: State, isLoggedIn: any) {
    state.isLoggedIn = isLoggedIn;
  },
  [Mutations.ERROR_REGISTER](state: State, errorRegister: any) {
    state.errorRegister = errorRegister;
  },
  [Mutations.ERROR_LOGIN](state: State, errorLogin: any) {
    state.errorLogin = errorLogin;
  },
  [Mutations.SUCCESS_REGISTER](state: State, successMessage: any) {
    state.successMessage = successMessage;
  },
  [Mutations.SET_USER_DATA](state: State, userData: any) {
    state.userData = userData;
  },
  // можно заменить на UPDATE_USER если отправлять его целиком с бэка
  [Mutations.ADD_ROLE](state: State, data: { user_id: string, role: string }) {
    const user = state.users.find(e => e._id == data.user_id)
    if (!user.roles.includes(data.role)) {
      user.roles.push(data.role)
      state.users = state.users.map(u => u._id == data.user_id ? user : u)
    }
  },
  [Mutations.REMOVE_ROLE](state: State, data: { user_id: string, role: string }) {
    const user = state.users.find(e => e._id == data.user_id)
    if (user.roles.includes(data.role)) {
      user.roles = user.roles.filter(r => r != data.role)
      state.users = state.users.map(u => u._id == data.user_id ? user : u)
    }
  },
  [Mutations.ADD_ROLE_TO_ALL](state: State, data: {role: string, users: any[]}) {
    const usersMap = new Set(data.users.map(u => u._id))
    state.users.forEach(user => {
      if (usersMap.has(user._id)) user.roles.push(data.role)
    })
  },
  [Mutations.REMOVE_ROLE_FROM_ALL](state: State, data: {role: string}) {
    state.users.forEach(user => {
      if (!user.deletion_mark) {
        user.roles = user.roles.filter((r: string) => r != data.role)
      }
    })
  },
  [Mutations.SET_SECTION](state: State, section: string) {
    state.activeSection = section
  },
  [Mutations.REMOVE_USER_FILE](state: State, data: {user_id: string, client_id: string, file_id: string}) {
    const user = state.users.find(u => u._id == data.user_id)
    // const p = [data.client_id, data.file_id].join('/') // зачем включать код клиента?
    user.files = user.files.filter((f:any) => f._id != data.file_id)
    state.users = state.users.map(u => u._id == user._id ? user : u)
    state.currentUser.files = [...user.files] // обязательно тут поменять, т.к. UI смотрит на этот объект
  },
  [Mutations.EXPORT_PROCESSING](state: State, flag: boolean) {
    state.exportProcessing = flag
  },
  [Mutations.SET_PINNED_USERS](state: State, data: {uid:string, from: number, to: number}) {
    state.users = state.users.map(user => {
      if (user._id === data.uid) return {...user, pinned_num: data.to}
      else if (user.pinned_num === data.to) return {...user, pinned_num: data.from}
      else return user
    })
  },
  [Mutations.RELOAD_AVATAR](state: State, data: string) {
    state.needReloadAvatar = data
  },
  [Mutations.ADD_FOLLOW_USER](state: State, data: {user_id: string, subscriber: string}) {
    const user = state.users.find(u => u._id === data.user_id)
    user.followers = [...user.followers, data.subscriber]
  },
  [Mutations.DEL_FOLLOW_USER](state: State, data: {user_id: string, subscriber: string}) {
    const user = state.users.find(u => u._id === data.user_id)
    user.followers = user.followers.filter(id => id != data.subscriber)
  },
  [Mutations.SET_VACATIONS](state: State, data: any[]) {
    state.vacations = data
  },
  [Mutations.SET_USER_VACATIONS](state: State, vac: {user: string, planned: any}) {
    const userVac = state.vacations?.find(item => vac.user == item.user._id)
    userVac.vacations = vac.planned
  },
};

const actions = {
  [Actions.LOGIN]: AuthService.login,
  [Actions.FORGOT_PASSWORD]: AuthService.forgotPasword,
  [Actions.RESET_PASSWORD]: AuthService.resetPassword,
  [Actions.RESET_PASSWORD_HARD]: AuthService.resetPasswordHard,
  [Actions.LOGIN_WITH_GOOGLE]: AuthService.loginWithGoogle,
  [Actions.LOGIN_WITH_OUTLOOK]: AuthService.loginWithOutlook,
  [Actions.GET_INFO_ABOUT_ME]: AuthService.getInfoAboutMe,
  [Actions.EDIT_USER]: Service.editUser,
  [Actions.INSERT_USER_INFO]: Service.insertNewUser,
  [Actions.SAVE_USERS]: Service.insertUserList,
  [Actions.CREATE_EVENT]: Service.createEvent,
  [Actions.UPDATE_EVENT]: Service.updateEvent,
  [Actions.DELETE_EVENT]: Service.deleteEvent,
  [Actions.CREATE_PERIODIC_EVENT]: Service.createPeriodicEvent,
  [Actions.UPDATE_PERIODIC_EVENT]: Service.updatePeriodicEvent,
  [Actions.DELETE_PERIODIC_EVENT]: Service.deletePeriodicEvent,
  [Actions.GET_EVENTS]: Service.getEvents,
  [Actions.GET_EVENTS_BY_CLIENT]: Service.getEventsByClient,
  [Actions.GET_USERS]: Service.getUsers,
  [Actions.GET_USERS_FILTERED]: Service.getUsersFiltered,
  [Actions.GET_USER]: Service.getUser,
  [Actions.LOGOUT]: AuthService.logout,
  [Actions.CHECK_USER_INFO]: Service.checkUserInfo,
  [Actions.MARK_USER_DELETED]: Service.markUserAsDeleted,
  [Actions.UNMARK_USER_DELETED]: Service.unmarkUserAsDeleted,
  [Actions.INSERT_ROLE]: Service.insertRole,
  [Actions.DELETE_ROLE]: Service.deleteRole,
  [Actions.SERVICE_SEND]: Service.serviceSendRequest,
  [Actions.SEND_IDEA]: Service.sendIdea,
  [Actions.SEND_VIOLATION_REPORT]: Service.sendViolationReport,
  [Actions.DELETE_HR_FILE]: Service.deleteHRFile,
  [Actions.EXPORT_USER]: Service.exportUserToSendsay,
  [Actions.EXPORT_USERS]: Service.exportUsersToSendsay,
  [Actions.SET_PINNED]: Service.pinUser,
  [Actions.MOVE_PINNED]: Service.pinedMoveUser,
  [Actions.USERS_LOAD_HRM]: Service.loadFromHRM,
  [Actions.USERS_LOAD_STRUCT_HRM]: Service.loadStructFromHRM,
  [Actions.RESET_TO_HRM]: Service.resetToHRM,
  [Actions.GET_VACATIONS]: Service.getVacations,
  [Actions.SAVE_VACATION]: Service.saveVacation,
  [Actions.DEL_VACATION]: Service.delVacation,
};

export default {
  // namespaced: true,
  state: store,
  getters,
  actions,
  mutations
};
