import * as Actions from './actions.type';
import * as Service from './post.service';
import * as Mutations from './mutations.type';

import * as Methods from './mutations/methods'
import { Comment } from './types'

export interface State {
  company_feed: any[],
  user_feed: any[],
  notif_feed: any[],
  feeds_metadata: {
    company: {
      pages: number,
      total: number
    },
    user: {
      pages: number,
      total: number
    }
  },
  posts: any[];
  posts_of_follows: any[],
  posts_of_groups: any[],
  currentPost: any,
  comments: Comment[];
  comments_feed: Comment[],
  mentions_feed: Comment[],
  postForEditing: any;
  commentForEditing: any;
  editPostVisible: boolean;
  editCommVisible: boolean;
  emoji_target: string,
  showEmojiPicker: boolean;
  emoji: string,
  postText: string,
}

const store: State = {
  company_feed: [],
  user_feed: [],
  notif_feed: [],
  feeds_metadata: {
    company: {
      pages: 0,
      total: 0
    },
    user: {
      pages: 0,
      total: 0
    }
  },
  postText: '',
  posts: [],
  posts_of_follows: [],
  posts_of_groups: [],
  currentPost: null,
  comments: [],
  comments_feed: [],
  mentions_feed: [],
  postForEditing: null,
  commentForEditing: null,
  editPostVisible: false,
  editCommVisible: false,
  emoji_target: '',
  showEmojiPicker: false,
  emoji: '',
};

function compare(a: any, b: any) {
  if (a.created < b.created) return 1;
  if (a.created > b.created) return -1;
  return 0;
}

const getters = {
  posts(state: State) {
    return state.posts;
  },
  currentPost(state: State) {
    return state.currentPost;
  },
  comments(state: State) {
    return state.comments;
  },
  comments_feed(state: State) {
    return state.comments_feed;
  },
  commentForEditing(state: State) {
    return state.commentForEditing;
  },
  editCommVisible(state: State) {
    return state.editCommVisible;
  },
  posts_company(state: State) {
    if (!state.posts) return []
    return state.posts.filter(p => p.parent.type == 'company')
  },
  posts_of_follows(state: State) {
    return state.posts_of_follows
  },
  posts_of_groups(state: State) {
    return state.posts.filter(p => p.parent.type == 'group') //posts_of_groups
  },
  posts_of_feed(state: State) {
    if (!state.posts) return []
    return state.posts.filter(p => p.parent.type == 'feed')
  },
  posts_of_system(state: State) {
    return state.posts.filter(p => p.author == 'system')
  },
  mentions_feed(state: State) {
    return state.mentions_feed
  },
  /**
   * Объединение всех типов постов в одну коллекцию
   */
  allPosts(state: State, getters: any): any[] {
    // для групп, определение что пост из доступной для пользователя группы
    const isAvailable = (id: string) => getters.groups_available.some((g: any) => g._id == id)
    return state.posts.filter(post => {
      // для групп проверяем доступность, для остальных - отсекаем новости компании
      return post.parent.type == 'group' ? isAvailable(post.parent.id) : post.parent.type != 'company'
    }).sort(compare)
  }
}

const actions = {
  [Actions.GET_COMPANY_FEED]: Service.getCompanyFeed,
  [Actions.GET_USER_FEED]: Service.getUserFeed,
  [Actions.SEND_NEW_POST]: Service.sendNewPost,
  [Actions.GET_POSTS]: Service.getPosts,
  [Actions.LOAD_POST]: Service.loadPost,
  [Actions.GET_POSTS_ALL]: Service.getAllPosts,
  [Actions.GET_POSTS_OF_BLOG]: Service.getPostsOfBlog,
  [Actions.GET_POSTS_OF_FLWS]: Service.getPostsOfFollows,
  [Actions.GET_POSTS_OF_GROUP]: Service.getPostsOfGroup,
  [Actions.GET_POSTS_OF_GROUPS]: Service.getPostsOfuserGroups,
  [Actions.GET_POST]: Service.getPost,
  [Actions.UPDATE_POST]: Service.updatePost,
  [Actions.SEND_LIKE]: Service.sendLike,
  [Actions.SEND_COMMENT]: Service.sendComment,
  [Actions.DELETE_POST]: Service.deletePost,
  [Actions.DELETE_COMMENT]: Service.deleteComment,
  [Actions.REPOST]: Service.repost,
  [Actions.EDIT_POST]: Service.editPost,
  [Actions.EDIT_COMMENT]: Service.editComment,
  [Actions.GET_COMMENTS]: Service.getComments,
  [Actions.GET_MENTIONS]: Service.getMentions,
  [Actions.VIEW_POST]: Service.viewPost,
};

// const replaceVal = (arr: any, val: any, newVal: any) => {
//   const n = arr.findIndex((a: any) => a == val);
//   return n == -1 ? arr : [...arr.slice(0, n), newVal, ...arr.slice(n + 1)]
// }

type WallPageResp = {
  result: any[],
  meta: {
    page: number,
    pages: number,
    total: number
  }
}

const mutations = {
  [Mutations.SET_POSTS]: Methods.setPosts,
  [Mutations.SET_POSTS_OF_FOLLOWS](state: State, posts: any[]) {
    state.posts_of_follows = posts;
  },
  [Mutations.SET_POSTS_OF_GROUPS](state: State, posts: any[]) {
    state.posts = [...state.posts, posts];
    state.currentPost = null
  },

  [Mutations.SET_POST](state: State, post: any[]) {
    state.currentPost = post;
  },

  [Mutations.ADD_POST]:         Methods.addPost,
  [Mutations.REMOVE_POST]:      Methods.removePost,
  [Mutations.SET_POST_VIEWED]:  Methods.setPostViewed,
  
  [Mutations.ADD_COMMENT_FOR_POST]:   Methods.addCommentToPost,
  [Mutations.REMOVE_COMMENT]:         Methods.removeComment,
  [Mutations.ADD_ANSWER_FOR_COMMENT]: Methods.addAnswerToComment,
  [Mutations.REMOVE_ANSWER]:          Methods.removeAnswer,

  [Mutations.ADD_LIKE_FOR_POST]:      Methods.addLikeToPost,
  [Mutations.ADD_LIKE_FOR_COMMENT]:   Methods.addLikeToComment,
  [Mutations.ADD_LIKE_FOR_ANSWER]:    Methods.addLikeToAnswer,

  [Mutations.CHANGE_POST]:    Methods.changePost,
  [Mutations.CHANGE_COMMENT]: Methods.changeComment,
  [Mutations.CHANGE_ANSWER]:  Methods.changeAnswer,

  [Mutations.SET_POST_FOR_EDITING](state: State, post: any) {
    state.postForEditing = post;
  },

  [Mutations.SET_COMMENT_FOR_EDITING](state: State, comment: any) {
    state.commentForEditing = comment;
  },

  [Mutations.SET_EDIT_POST_VISIBLE](state: State, visible: any) {
    state.editPostVisible = visible;
  },

  [Mutations.SET_EDIT_COMMENT_VISIBLE](state: State, visible: any) {
    state.editCommVisible = visible;
  },
  [Mutations.SET_COMMENTS_FEED](state: State, data: any) {
    state.comments_feed = data
  },
  [Mutations.SET_MENTIONS_FEED](state: State, data: any) {
    state.mentions_feed = data
  },
  
  [Mutations.SET_COMPANY_FEED](state: State, data: WallPageResp) {
    state.company_feed.splice(data.meta.page * 20, 20, ...data.result)
    state.feeds_metadata.company = data.meta
    state.currentPost = null // сброс чтоб не глючило после возврата в ленту со страницы поста
  },
  [Mutations.SET_USER_FEED](state: State, data: WallPageResp) {
    state.user_feed.splice(data.meta.page * 20, 20, ...data.result)
    state.feeds_metadata.user = data.meta
    state.currentPost = null
  },
  [Mutations.TOGGLE_EMOJI_PICKER](state: State, data: string) {
    state.emoji_target = state.showEmojiPicker ? '' : data // чтобы различать к какому полю прикреплять эмоджи
    state.showEmojiPicker = !state.showEmojiPicker  // флаг показа окна
    state.emoji = ''  // само выбранное эмодзи
  },
  [Mutations.SET_EMOJI](state: State, data: string) {
    state.emoji = data
  },
  [Mutations.SET_POST_TEXT](state: State, text: string) {
    state.postText = text
  },
};

export default {
  state: store,
  getters,
  actions,
  mutations,
};
