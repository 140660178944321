import Vue from 'vue';
import { AxiosError, AxiosResponse } from 'axios';

import store from "@/store";

import * as Mutations from "./mutations.type"
import { UPD_ITEM } from "@/store/common/mutations.type"

import { Task, ChangingReq, MovingReq, DelReq, Desk } from './types'
import { UploadResponse } from '../common/types'

const config = {
  onUploadProgress: function(progressEvent:any) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    store.state.common.progress = percentCompleted
  }
}

export const getTaskOfDesk = async ({commit, rootState}: any, _id: string) => {
  const response:AxiosResponse = await Vue.axios.get(`api/task/${_id}`)
  const { result } = response.data
  commit('common/'+UPD_ITEM, {
    state: rootState.task_tracker, 
    field: 'activeTask', 
    data: result as Task
  }, {root: true})
}

// Создание задачи без привязки к доскам - например задача для онбординга, мероприятия или любого другого раздела
// В результате нужен только ID нового объекта чтобы сохранить его где то в другом месте
export const saveTaskAlone = async ({commit}: any, data: Task) => { 
  const response:AxiosResponse = await Vue.axios.post(`api/task`, data)
  return response.data.result
}

// Добавление задачи в панель доски
export const addTaskToDesk = async ({commit}: any, data: ChangingReq) => { 
  const response:AxiosResponse = await Vue.axios.put(`api/task_tracker/${data.desk_id}/${data.lane_id}/add_task`, data.task)
  const { result } = response.data
  commit(Mutations.ADD_TASK, {desk_id: data.desk_id, lane_id: data.lane_id, task: result as Task})
  return result
}

export const editTaskOnDesk = async ({commit, rootState}: any, data: ChangingReq) => {
  if (data.formData) {
    commit('common/SET_PROGRESS', 0, {root: true})
    commit('common/SET_PROGRESS_VISIBLE', true, {root: true})
    const  { data: uploadedData } : AxiosResponse<UploadResponse> = await Vue.axios.post(`api/upload`, data.formData, config)
    const { url, thumb, file, fileName } = uploadedData.results[0]
    data.task.attachments.push({url, thumb, mime: file.type, name: fileName})
    setTimeout(() => { commit('common/SET_PROGRESS_VISIBLE', false, {root: true}) }, 1500)
  }
  const response:AxiosResponse = await Vue.axios.put(`api/task/${data.task._id}`, data.task)
  const { result } = response.data
  if (data.formData) {
    commit(Mutations.CHANGE_FILES, (result as Task).attachments)
    delete data.formData
  } else {
    // изменение в списке задач доски
    commit(Mutations.CHANGE_TASK, {desk_id: data.desk_id, lane_id: data.lane_id, task: result as Task})
    // изменение открытой задачи
    commit('common/'+UPD_ITEM, {
      state: rootState.task_tracker, 
      field: 'activeTask', 
      data: result as Task
    }, {root: true})
  }
  
  return result
}

export const delTask = async ({commit, rootState}: any, data: DelReq) => {
  const response:AxiosResponse<{result: boolean}> = await Vue.axios.delete(`api/task/${data.task_id}`)
  const { result } = response.data
  commit(Mutations.REMOVE_TASK, data)
  commit('common/'+UPD_ITEM, {
    state: rootState.task_tracker, 
    field: 'activeTask', 
    data: null
  }, {root: true})
  return result
}

// удаление панели с задачами
export const delLaneOfDesk = async ({commit, rootState}: any, data: {desk_id: string, lane_id: string}) => {
  const response:AxiosResponse = await Vue.axios.delete(`api/task_tracker/${data.desk_id}/lane/${data.lane_id}`)
  const { result } = response.data
  commit('common/'+UPD_ITEM, {
    state: rootState.task_tracker,
    data: result as Desk  // обновится поле item со всей доской
  }, { root: true })
}

// Перемещение задачи
export const moveTaskToLane = async ({commit, rootState}: any, data: MovingReq) => {
  const response:AxiosResponse = await Vue.axios.put(`api/task_tracker/${data.desk_id}/move_task`, data)
  const { result } = response.data
  commit('common/'+UPD_ITEM, {
    state: rootState.task_tracker,
    data: result as Desk  // обновится поле item со всей доской
  }, { root: true })
}

export const finishDesk = async ({commit, rootState}: any, desk_id: string) => {
  const response:AxiosResponse = await Vue.axios.put(`api/task_tracker/${desk_id}/finish`)
  const { result } = response.data
  commit('common/'+UPD_ITEM, {
    state: rootState.task_tracker,
    data: result as Desk  // обновится поле item со всей доской
  }, { root: true })
}