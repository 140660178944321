import dayjs from "dayjs";
import 'dayjs/locale/ru'
import 'dayjs/locale/uk'

const minMax = require('dayjs/plugin/minMax')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const isToday = require('dayjs/plugin/isToday')
const isBetween = require('dayjs/plugin/isBetween')
const utc = require('dayjs/plugin/utc')
const duration = require('dayjs/plugin/duration')
const relativeTime = require('dayjs/plugin/relativeTime')
const arraySupport = require("dayjs/plugin/arraySupport");
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(arraySupport);
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(isToday)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(minMax)
dayjs.extend(utc)
dayjs.extend(timezone)

export const $d = dayjs

export default {
  install(Vue) {
    Vue.prototype.$dayjs = dayjs;
    // Vue.prototype.$date.prototype = dayjs.prototype;
    // Пример дополнительной функции расширяющей глобальный объект
    // Vue.prototype.$date.prototype.prettyDate = function(date) {
    //   date = dayjs(date);
    //   const diff = this.diff(date, "day");
    //   if (!diff) {
    //     return this.format("h mm A");
    //   } else if (diff === 1) {
    //     return `Yesterday, ${this.format("h mm A")}`;
    //   } else {
    //     return this.format("MMMM DD, YYYY h  mm A");
    //   }
    // };
  }
};