import Vue from 'vue';

import * as Mutations from './mutations.type'

import {Mailing} from './types'

// Создание 
export const createMailing = async (context: any, mailing: Mailing) => {
  const {data} = await Vue.axios.post(`api/mailing`, mailing)
  if (mailing.active) {
    await Vue.axios.put(`api/mailing/startJob/${mailing.workspace}`, mailing)
    data.result.started = true
  }
  context.commit(Mutations.ADD_MAILING, data.result);
  return data.result
};

// Получение всех рассылок для клиента
export const getMailings = (context: any, workspace: string) => {
  return Vue.axios
    .get('api/mailing', { params: 
      {
        filter: {workspace}
      }
    }).then((resp: any) => {
      context.commit(Mutations.SET_MAILINGS, resp.data.result)
    }).catch((err:any)=> {
      //
    })
}

// Получение одной рассылки клиента
export const getMailing = (context: any, id: string) => {
  return Vue.axios
    .get(`api/mailing/${id}`)
    .then((resp:any) => {
      context.commit(Mutations.SET_MAILING, resp.data.result)
      return resp.data.result
    }).catch((err:any)=> {
      //
    })
}

// Изменение одной рассылки клиента
export const saveMailing = async (context: any, data: Mailing) => {
  const {data: old} = await Vue.axios.get(`api/mailing/${data._id}`)
  const resp:any = await Vue.axios.put(`api/mailing/${data._id}`, data)
   // .then((resp:any) => {
  context.commit(Mutations.REPLACE_MAILING, resp.data.result)
  console.log('data.schedule',old.result.schedule,'resp:',resp.data.result.schedule);
  
  if (old.result.schedule != resp.data.result.schedule) {
    await Vue.axios.put(`api/mailing/restartJob/${data.workspace}_${data.action}`, data)
    resp.data.result.restarted = true
  }
  else if (data.active) {
    await Vue.axios.put(`api/mailing/startJob/${data.workspace}`, data)
    resp.data.result.started = true
  }
  else {
    await Vue.axios.put(`api/mailing/stopJob/${data.workspace}_${data.action}`, data)
    resp.data.result.stopped = true
  }
  return resp.data.result 
}

// Удаление одной рассылки клиента
export const deleteMailing = async (context: any, id: string) => {
  await Vue.axios.delete(`api/mailing/${id}`)
  context.commit(Mutations.REMOVE_MAILING, id)
  return id
}