import { Comment } from '@/store/post/types'
import { State } from '../module'
import { Desk, Task } from '../types'

const getLane = (state: State, id: string) => state.item?.lanes.find(l => l._id === id)

export function addTask(state: State, data: {task: Task, desk_id: string, lane_id: string}) {
  const lane = getLane(state, data.lane_id)
  lane?.tasks.push(data.task)
}

export function changeTask(state: State, data: {task: Task, desk_id: string, lane_id: string}) {
  const lane = getLane(state, data.lane_id)
  if (!lane?.tasks) return
  lane.tasks = lane.tasks.map(t => t._id == data.task._id ? data.task : t)
}

export function removeTask(state: State, data: {task_id: string, desk_id: string, lane_id: string}) {
  const lane = getLane(state, data.lane_id)
  if (!lane?.tasks) return
  lane.tasks = lane.tasks.filter(t => t._id != data.task_id)
}

export function changeFiles(state: State, attachments: any[] ) {
  if (state.activeTask) state.activeTask.attachments = attachments
}

// добавление комментария к задаче
export function addCommentToTask(state: State, comment: Comment) {
  if (state.activeTask == null) return
  state.activeTask.comments = [comment, ...state.activeTask.comments]
}

// ищменение комментария в задаче
export function editCommentToTask(state: State, comment: Comment) {
  if (state.activeTask == null) return
  state.activeTask.comments = state.activeTask.comments.map(c => c._id == comment._id ? comment : c)
}

export function deleteCommentInTask(state: State, comment_id: string) {
  if (state.activeTask == null) return
  state.activeTask.comments = state.activeTask.comments.filter(c => c._id != comment_id)
}