export const SET_SERVICES = 'SET_SERVICES' // list of old services
export const SET_ACTIVE_PROCESS = 'SET_ACTIVE_PROCESS' // new BP
export const SET_BUSINESS_PROCESSES = 'SET_BUSINESS_PROCESSES' // new list of BP
export const SET_BUSINESS_PROCESS = 'SET_BUSINESS_PROCESS' // new list of BP
export const SET_ORDERS_TABLE = 'SET_ORDERS_TABLE' // new list of active BP
export const SET_MY_PROCESSES = 'SET_MY_PROCESSES' // new list of empl's orders
export const SET_BP = 'SET_BP'
export const ADD_BP = 'ADD_BP'
export const REMOVE_BP = 'REMOVE_BP';
export const ADD_COMMENT_FOR_SERVICE = 'ADD_COMMENT_FOR_SERVICE';
// Steps
export const ADD_STEP = 'ADD_STEP'
export const CHANGE_STEP = 'CHANGE_STEP'
export const REMOVE_STEP = 'REMOVE_STEP'
// groups of BP
export const SET_SERVICE_GROUPS = 'SET_SERVICE_GROUPS'
export const ADD_SERVICE_GROUP = 'ADD_SERVICE_GROUP'
export const SET_SERVICE_GROUP = 'SET_SERVICE_GROUP'
export const REMOVE_SERVICE_GROUP = 'REMOVE_SERVICE_GROUP';