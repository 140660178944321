import * as Actions from './actions.type'
import * as Mutations from './mutations.type'
import * as Service from './notifications.service'


import {UserNotifications} from './notif.types'
export interface NotificationState {
  all: any[],
  events: any[],
  groups: any[],
  polls: any[],
  posts: any[],
  services: any[],
  shop: any[],
  resp: any[],
  tasks: any[],
  adapt: any[],
  other: any[],
  unread: number,
  client_notifications: UserNotifications | null;
  user_notifications: UserNotifications | null;
}

const store: NotificationState = {
  all: [],
  events: [],
  groups: [],
  polls: [],
  posts: [],
  services: [],
  shop: [],
  resp: [],
  tasks: [],
  adapt: [],
  other: [],
  unread: 0,
  client_notifications: null, //
  user_notifications: null, //
}

const actions = {
  [Actions.GET_NOTIFICATIONS_FEED]: Service.getNotifications,
  [Actions.GET_ONE_NOTIFICATION]: Service.getNotification,
  [Actions.GET_UNREAD_COUNT]: Service.getNotifCount,
  [Actions.VIEW_NOTIFICATION]: Service.viewNotification,
  [Actions.VIEW_ALL]: Service.viewAllNotifications,
  [Actions.GET_TMPL_NOTIFICATIONS]: Service.getTmplNotifications,
  [Actions.SAVE_TMPL_NOTIFICATIONS]: Service.saveTmplNotifications,
}

// допустимые секции
enum Sections {
  all = 'all',
  events = 'events',
  groups = 'groups',
  polls = 'polls',
  posts = 'posts',
  services = 'services',
  shop = 'shop',
  resp = 'resp',
  tasks = 'tasks',
  adapt = 'adapt',
  other = 'other',
}

type postViewedResp = {
  post_id: string,
  where: string,
  view: {
    user: string,
    time: Date,
    _id: string
  }
}

const getters = {
  // только для определения настроек для попапов
  client_popups_templ_settings(state: NotificationState) {
    return (state.client_notifications?.notifications || [])
        .reduce((acc, el) => {acc[el.name] = el.channel.includes('system'); return acc}, {})
  },
  user_popups_templ_settings(state: NotificationState) {
    return (state.user_notifications?.notifications || state.client_notifications?.notifications || [])
      ?.reduce((acc, el) => {acc[el.name] = el.channel.includes('system'); return acc}, {}) || {}
  },
}

const mutations = {
  [Mutations.SET_NOTIFICATIONS_FEED](state: NotificationState, data: {section: Sections, posts: any[]}) {
    state[data.section] = data.posts;
  },
  [Mutations.ADD_NOTIFICATIONS_FEED](state: NotificationState, data: {section: Sections, posts: any[]}) {
    state[data.section] = [...data.posts, ...state[data.section]];
    if (data.section != 'all') {
      state.all = [...data.posts, ...state.all]
    }
  },
  [Mutations.SET_UNREAD_COUNT](state: NotificationState, unread: number) {
    state.unread = unread
  },
  [Mutations.SET_NOTIF_VIEWED](state: NotificationState, data: postViewedResp) {
    const feed = state[data.where.substring(14)]
    const post = feed.find(p => p._id == data.post_id)
    const viewed = post.views.some(v => v.user == data.view.user)
    if (!viewed) post.views = [...post.views, data.view] 
  },
  [Mutations.SET_ALL_VIEWED](state: NotificationState, data) {
    const toBeUpdated = state[data.notifSec].filter(n => data.postIds.includes(n._id))
    toBeUpdated.forEach(n => {
      const viewed = n.views.some(v => v.user === data.userId);
      if (!viewed) {
        n.views.push({
          user: data.userId,
          time: new Date(),
          _id: n._id,
        });
      }
    })
  },
  [Mutations.ADD_NOTIFS_COUNT](state: NotificationState, num: number) {
    state.unread = state.unread + num
  },
  [Mutations.SET_TMPL_NOTIFICATIONS](state: NotificationState, data: UserNotifications) {
    if (data.user_id) state.user_notifications = data
    else state.client_notifications = data
  },
}

export default {
  namespaced: true,
  state: store,
  getters,
  actions,
  mutations,
};