export const ADD_TASK = 'ADD_TASK'; // добавление новой задачи в доску вместо обновления всей доски
export const CHANGE_TASK = 'CHANGE_TASK'; // изменение одной задачи на доске
export const REMOVE_TASK = 'REMOVE_TASK'; // удаление одной задачи из доски
export const REMOVE_LANE = 'REMOVE_LANE'; // удаление одной задачи из доски
export const CHANGE_FILES = 'CHANGE_FILES'; // изменение файлов без затирания остальных полей

export const ADD_COMMENT_FOR_TASK = 'ADD_COMMENT_FOR_TASK';
export const CHANGE_COMMENT_FOR_TASK = 'CHANGE_COMMENT_FOR_TASK';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const REMOVE_ANSWER = 'REMOVE_ANSWER';
