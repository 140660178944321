import * as Actions from './actions.type';
import * as Service from './group.service';
import * as Mutations from './mutations.type';

interface State {
  groups: any[];
  currentGroup: any;
  participantsRequest: boolean | null;
  invite: any | null;
  invites: any[],
  requests: any[],
  groupsUserCanRead: string[]
}

const store: State = {
  groups: [],
  currentGroup: null,
  participantsRequest: null,
  invite: null,
  invites: [],
  requests: [],
  groupsUserCanRead: []
};

const getters = {
  groups(state: State) {
    return state.groups;
  },
  groupById(state: State) {
    return id => state.groups.find(g => g._id == id)
  },
  currentGroup(state: State) {
    return state.currentGroup;
  },
  participantsRequest(state: State) {
    return state.participantsRequest;
  },
  invite(state: State) {
    return state.invite;
  },
  invites(state: State) {
    return state.invites;
  },
  group_requests(state: State) {
    return state.requests
  },
  groups_available(state: State) {
    return state.groupsUserCanRead
  }
};

const actions = {
  [Actions.CREATE_GROUP]: Service.createGroup,
  [Actions.GET_GROUPS]: Service.getGroups,
  [Actions.GET_GROUPS_BY_CLIENT]: Service.getGroupsByClient,
  [Actions.GET_CURRENT_GROUP]: Service.getCurrentGroup,
  [Actions.DELETE_GROUP]: Service.deleteGroup,
  [Actions.EDIT_GROUP]: Service.editGroup,
  [Actions.CREATE_PARTICIPANT]: Service.createParticipant,
  [Actions.GET_PARTICIPANTS_REQUEST]: Service.getParticipantsRequest,
  [Actions.APPROVE_PARTICIPANTS_REQUEST]: Service.approveParticipantsRequest,
  [Actions.REJECT_REQUEST]: Service.rejectParticipantRequest,
  [Actions.DELETE_PARTICIPANT]: Service.deleteParticipant,
  [Actions.CREATE_INVITE]: Service.createInvite,
  [Actions.GET_INVITE]: Service.getInvite,
  [Actions.GET_INVITES]: Service.getGroupInvites,
  [Actions.GET_INVITES_FOR_USER]: Service.getUserInvites,
  [Actions.APPROVE_INVITE]: Service.approveInvite,
  [Actions.CANCEL_INVITE]: Service.cancelInvite,
  [Actions.REPLACE_GROUPS_OWNER]: Service.replaceGroupsOwner,
  [Actions.CHANGE_GROUP_OWNER]: Service.changeGroupOwner,
  [Actions.GET_REQUESTS_TO_MY_GROUPS]: Service.getRequests,
  [Actions.GET_GROUPS_USER_CAN_READ]: Service.getGroupsThatUserCanRead,
  [Actions.SET_ADMIN]: Service.setAdmin,
};

const mutations = {
  [Mutations.ADD_GROUP](state: State, group: any) {
    state.groups = [group, ...state.groups];
  },
  [Mutations.SET_GROUPS](state: State, groups: any) {
    state.groups = groups;
  },
  [Mutations.SET_CURRENT_GROUP](state: State, group: any) {
    state.currentGroup = group;
  },
  [Mutations.REMOVE_GROUP](state: State, groupId: any) {
    if (state.groups) {
      const index = state.groups.findIndex(({ _id }) => _id === groupId);
      state.groups.splice(index, 1)
    }
  },
  [Mutations.UPDATE_GROUP](state: State, group: any) {
    state.currentGroup = group;
    if (state.groups) {
      const index = state.groups.findIndex(({ _id }) => _id === group._id);
      state.groups.splice(index, 1, group)
    }
  },
  [Mutations.SET_PARTICIPANTS_REQUEST](state: State, req: boolean) {
    state.participantsRequest = req;
  },
  [Mutations.ADD_INVITE](state: State, invite: any) {
    state.invites = [...state.invites, invite];
  },
  [Mutations.SET_INVITE](state: State, invite: any) {
    state.invite = invite;
  },
  [Mutations.SET_INVITES](state: State, invites: any) {
    state.invites = invites;
  },
  [Mutations.SET_REQUESTS](state: State, requests: any[]) {
    state.requests = requests
  },
  [Mutations.SET_GROUPS_USER_CAN_READ](state: State, data: any[]) {
    state.groupsUserCanRead = data
  },
  [Mutations.DELETE_INVITE](state: State, id: string) {
    state.invites = state.invites.filter(i => i._id != id);
  },
  [Mutations.GROUP_REQUEST_DELETE](state: State, data: {groupId: string, userId: string}) {
    const gr = state.groups.find(g => g._id == data.groupId)
    gr.requests_ref = gr.requests_ref.filter(r => r.user_ref._id != data.userId)
  },
  [Mutations.SET_PARTICIPANTS](state: State, data: {group_id: string, participants: any[]}) {
    const gr = state.groups.find(g => g._id == data.group_id)
    gr.participants_ref = data.participants
  }
};

export default {
  state: store,
  getters,
  actions,
  mutations,
};
