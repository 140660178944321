import Vue from 'vue';

import { SET_NOTIFICATIONS_FEED, ADD_NOTIFICATIONS_FEED, SET_NOTIF_VIEWED, SET_TMPL_NOTIFICATIONS, SET_ALL_VIEWED } from './mutations.type'
import { AxiosResponse, AxiosError } from 'axios';
import { SET_UNREAD_COUNT } from './mutations.type';

import { UserNotifications, ClientNotifications } from './notif.types';

type NotificationRequest = {
  client_id: string,
  section: string,
  user_id: string,
  read: boolean,
  time: string,
  append: boolean,
}

type NotificationResponse = {
  //
}


// Получение нотификаций по определенной секции для определенного пользователя
export const getNotifications = async (context: any, data: NotificationRequest)  => {
  try {
    const resp: AxiosResponse<NotificationResponse> = await Vue.axios
      .get(`api/notification/${data.client_id}/${data.section}/${data.user_id}/${data.time || 'now'}?read=${+ data.read}`)
    const result = { section: data.section, posts: resp.data }
    if (data.append)
      context.commit(ADD_NOTIFICATIONS_FEED, result)
    else
      context.commit(SET_NOTIFICATIONS_FEED, result)
    return result
  } catch (error) {
    
  }
}

interface NotificationOneRequest {
  post_id: string, // код поста-уведомления
  action: string, // строка с полным кодом действия типа GROUPS_NEW
}
export const getNotification = async (context: any, NotifData: NotificationOneRequest) => {
  const {data}: AxiosResponse = await Vue.axios.get(`api/post/${NotifData.post_id}`)
  // const section = {
  //   group: 'groups',
  //   user: 'blogs',
  //   'system.GROUPS.NEW_USER': 'groups',
  //   'EVENTS.DELETED': 'events',
  //   'EVENTS.TIME_CHANGED': 'events',
  // }[data.parent.type] || data.parent.type
  context.commit(ADD_NOTIFICATIONS_FEED, { 
    section: NotifData.action.split('_')[0].toLowerCase(), 
    posts: [data.result] 
  })
}

type NotifCountReq = {
  client_id: string,
  user_id: string,
}
type NotifCountResp = {
  count: number
}
export const getNotifCount = async ({commit}: any, data: NotifCountReq) => {
  const resp: AxiosResponse<NotifCountResp> = await Vue.axios
      .get(`api/notification/unread/${data.client_id}/${data.user_id}`)
  commit(SET_UNREAD_COUNT, resp.data.count)
}

// Типизирование запроса и ответа с бэка
type postViewData = {
  _id: string,
  where: string,
  user_id: string,
}
type postViewResult = {
  post_id: string,
  where: string,
  view: {
    time: string,
    user: string,
    _id: string,
  }
}
type manyPostViewData = {
  where: string,
  user_id: string,
}
type manyPostViewResult = {
  userId: string,
  notifSec: string,
  postIds: Array<string>
}

export const viewNotification = async (context: any, data: postViewData) => {
  const url = `/api/post/${data._id}/view`
  const payload = {user_id: data.user_id, where: data.where}
  try {
    const res : AxiosResponse<postViewResult> = await Vue.axios.put<postViewResult>(url, payload)
    context.commit(SET_NOTIF_VIEWED, res.data)
    return res.data
  } catch (error) {
    if (Vue.axios.isAxiosError(error) && error.response) {
      return {
        error: error.message,
        stack: error.stack,
        data: error.response.data,
      }
    } else {
      return {
        error
      }
    }
  }
  
}

export const viewAllNotifications = async (context: any, data: manyPostViewData) => {
  const url = `/api/post/all/view`
  const payload = { user_id: data.user_id, where: data.where }
  try {
    const res : AxiosResponse<manyPostViewResult> = await Vue.axios.put<manyPostViewResult>(url, payload)
    context.commit(SET_ALL_VIEWED, res.data)
    return res.data
  } catch (error) {
    if (Vue.axios.isAxiosError(error) && error.response) {
      return {
        error: error.message,
        stack: error.stack,
        data: error.response.data,
      }
    } else {
      return {
        error
      }
    }
  }
}

// Настройки пользовательских уведомлений или для организации (когда user_id пустой)
type UserNotificationsParams = {
  client_id: string,
  user_id?: string, // код сотрудника или пусто когда для всей компании
  code: string, // код события
  channel: string, // канал уведомлений
  val: boolean, // значение да/нет
}
// 
export const getTmplNotifications = async (context: any, params: UserNotificationsParams) => {
  const url = params.user_id
    ? `api/user/notifications/${params.user_id}` 
    : `api/user/notifications/client/${params.client_id}`
  let data: UserNotifications | ClientNotifications;
  if (params.user_id) {
    const resp: AxiosResponse<UserNotifications> = await Vue.axios.get(url)
    data = resp.data
  } else {
    const resp: AxiosResponse<ClientNotifications> = await Vue.axios.get(url)
    data = resp.data
  }
  // полностью обновляем юзеров, т.к. могут сильно поменяться местами строки
  return context.commit(SET_TMPL_NOTIFICATIONS, data) 
}

export const saveTmplNotifications = async (context: any, params: UserNotificationsParams) => {
  const {data}: AxiosResponse<{result: UserNotifications}> = await Vue.axios.put(`api/user/notifications`, params)
  // полностью обновляем юзеров, т.к. могут сильно поменяться местами строки
  return context.commit(SET_TMPL_NOTIFICATIONS, data) 
}